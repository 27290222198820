import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../../Common/common'
import { UpdateGsmWebConfigDetails, UpdateSlaveWebConfigDetails } from '../../../../Common/Utils'
import { Switch } from 'antd'

export default function WebConfig(props) {

   const [config, setConfig] = useState( { MIN: '', MAX: ''})
   const [disable, setDisable] = useState(true)
   const [btnFlag, setbtnFlag] = useState(false)
   

   useEffect(() => {
      setDisable(false)
      setConfig({ MIN: JSON.parse(props?.GSMdata?.temp_config)?.MIN||'', MAX: JSON.parse(props?.GSMdata?.temp_config)?.MAX||''})
   }, [])

   const onHandalUpdate = async () => {
      setbtnFlag(true)
      const data = await UpdateSlaveWebConfigDetails({temp_config:{MIN:config?.MIN,MAX:config?.MAX}}, props?.GSMdata?.unique_id)
      if (data.success === true) {
         toast.success('Successfully Updated Details')
         setDisable(false)
         setbtnFlag(false)
         props.onConsumerUpdateDate()
      } else {
         toast.error('Something Went Wrong')
         setbtnFlag(false)
      }
   }

   return (
      <div>
         <section class="card-diff-section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Web Configration</h4>
                           <div class="card-header-action">
                              <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>
                           </div>
                        </div>
                        <div className='card-body'>
                           
                           <div className='d-flex tw-items-center mt-2'>
                              <div className=' d-flex tw-flex-row ' style={{ fontSize: '15px', width: '20%' }}><b>1] </b><span>&#10240;</span><b> Temperature:- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 <div class="form-group col-md-4 d-flex mb-0">
                                    <label className='d-flex col-3 tw-flex-col tw-justify-center'>Min value:- </label>
                                    <input class="form-control  d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder="" disabled={!disable} onChange={(e) => (setConfig({ ...config, MIN: e.target.value  }))} value={config?.MIN} />
                                 </div>
                                 <div class="form-group col-md-4 d-flex mb-0">
                                    <label className='d-flex col-4 tw-flex-col tw-justify-center'>Max value:- </label>
                                    <input class="form-control  d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder="" disabled={!disable} onChange={(e) => (setConfig({ ...config, MAX: e.target.value  }))} value={config?.MAX} />
                                 </div>
                                 
                              </div>
                           </div>
                           
                           <div class="form-row p-3" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                           </div>
                           {disable ? <>
                              <div class="text-left mb-3 ml-3">
                                 <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" onClick={onHandalUpdate} />

                                 <Link class="btn btn-light text-dark " onClick={() => setDisable(false)}>Cancel</Link>
                              </div></> : ''}
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}
