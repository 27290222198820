import * as React from "react"

import { cn } from "../../lib/utils"

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "tw-rounded-lg tw-border tw-bg-card tw-text-card-foreground tw-shadow-sm",
      className
    )}
    {...props} />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-flex tw-flex-col tw-space-y-1.5 tw-p-6", className)}
    {...props} />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "tw-text-2xl tw-font-semibold tw-leading-none tw-tracking-tight",
      className
    )}
    {...props} />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("tw-text-sm tw-text-muted-foreground", className)}
    {...props} />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("tw-p-6 tw-pt-0", className)} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-flex tw-items-center tw-p-6 tw-pt-0", className)}
    {...props} />
))
CardFooter.displayName = "CardFooter"

const TaskFilterButton = ({
  label,
  count,
  onClick,
  mainStyle,
  countStyle,
  countInLineStyle = {},
  mainInlineStyle = {},
  icon,
  duration = null,
  durationClassName
}) => {
  return (
      <div style={{ ...mainInlineStyle, borderBottomStyle: 'dashed', borderBottomWidth: '1px' }}
          className={`tw-justify-center tw-font-medium tw-ring-offset-background tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-flex tw-gap-2 tw-text-base tw-items-center hover:tw-rounded tw-p-2 tw-cursor-pointer tw-duration-150 ${mainStyle ? mainStyle : ""} `} onClick={onClick}>
          <div className='' >{icon}</div>
          <label className="tw-font-semibold peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70 tw-mb-0 tw-cursor-pointer">{label}</label>
          <div style={countInLineStyle} className={`tw-ml-auto tw-text-white !tw-font-bold tw-cursor-pointer tw-inline-flex tw-items-center tw-px-2.5 tw-py-1 tw-text-xs  tw-transition-colors focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 tw-rounded-full tw-border-transparent tw-gap-1 ${countStyle ? countStyle : ""}`}>{count || 0}</div>
          {
              duration &&
              <span class={`tw-inline-flex tw-items-center tw-whitespace-nowrap tw-border tw-font-semibold tw-transition-colors focus:tw-outline-none focus:tw-ring-1 focus:tw-ring-ring focus:tw-ring-offset-1 tw-text-sm tw-px-1.5 tw-py-0.5 tw-rounded tw-border-none ${durationClassName ? durationClassName : ""}`}>{duration}</span>
          }
      </div>
  )
}


export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent,TaskFilterButton }
