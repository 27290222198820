import React, { useEffect, useState } from 'react'
import { getPowerSourceTotalGSM, onGetConsumerIncolumeName } from '../../Common/Utils';
import { Card, CardContent, CardFooter, CardHeader } from '../../Common/ui/card';
import ReactApexChart from "react-apexcharts";
import loaderGif from '../../assets/img/loading_chart.gif'
import { Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
const sum = (arr) => {
	const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
	return result
}
export default function ModemDashboard(props) {
    const {permision}=props
    const [PowerSource, setPowerSource] = useState({ series: [], category: [], code: [] })
    const [TableData, setTableDataInApi] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ModelId, setModelId] = useState('')
    const [ModalLoad, setModalLoad] = useState(false)
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });

    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    let colors = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dcf', '#FF7700', '#554994', "#D36B00", "#0096FF"];

    useEffect(()=>{
        console.log('permision',permision);
        if(permision){
          const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
        ongetPowerSourceTotalGSM(infoRolesObj)
        }
    },[permision])

    const GetConsumerIncolumeName = async (isbetween, col, colname, value, CARD_ID) => {
      setIsModalVisible(true)
      setModalLoad(true)
      setModelId(`${colname}`)
      let obj = {}
     
        obj = { isbetween: isbetween, colName: col, value: value, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
      
  
      const data = await onGetConsumerIncolumeName(obj)
      if (data.success === true) {
        let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
        setTableDataInApi(tempData)
        setModalLoad(false)
      } else {
        console.log('somthing want wrong')
        setModalLoad(false)
  
      }
    }

    const ongetPowerSourceTotalGSM = async (info) => {
        setPowerSource({ series: [], category: [] })
        const data = await getPowerSourceTotalGSM(info);
        if (data.success === true) {
          const chartData = data?.data?.data[0];
          const obj = {
            series: [chartData?.external_count || 0, chartData?.battery_count || 0, chartData?.battery_disable_count || 0, chartData?.null_count || 0],
            category: [`External Power (${chartData?.external_count || 0})`, `Battery (${chartData?.battery_count || 0})`, `Without Battery (${chartData?.battery_disable_count || 0})`,`null (${chartData?.null_count || 0})`],
          };
          setPowerSource(obj);
        } else {
          console.log("something went warong");
        }
      }


      var options1 = getPieChartOptions(
        350,
        PowerSource.category,
        colors,
        function (event, chartContext, config) {
          var date_index = config.dataPointIndex;
          if (date_index == 0) {
            GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0], `(JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source')=1  AND (JSON_VALUE(alert_disable,'$.mmd_status_pwr_source') IS NULL OR  JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')!=0))`)
          } else if (date_index == 1) {
            GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0], `((JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')=1 OR JSON_VALUE(alert_disable,'$.mmd_status_pwr_source') IS NULL ) AND JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source')=0 )`)
          } else if(date_index == 2) {
            GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0],
              `JSON_VALUE(alert_disable,'$.mmd_status_pwr_source')=0`);
          }else {
            GetConsumerIncolumeName(false, '', PowerSource.category[date_index].split('(')[0],
              `JSON_VALUE(heartbeat_last_data,'$.mmd_status_pwr_source') IS null`);
          }
        },
        function (val, opt) {
          return PowerSource.series[opt.seriesIndex];
        }
      );

      function getPieChartOptions(height, labels, colors, dataPointSelectionHandler, dataLabelFormatter) {
        return {
          chart: {
            height: height,
            type: 'pie',
            events: {
              dataPointSelection: dataPointSelectionHandler,
              dataPointMouseEnter: function (event) {
                if(event.path){
                  event.path[0].style.cursor = "pointer";
                }
              }
            }
          },
          legend: {
            position: 'right',
            verticalAlign: 'bottom',
            width: 150
          },
          labels: labels,
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            formatter: dataLabelFormatter || function (val) { return val; }, // Default formatter
            offsetX: 0,
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: 'right',
                width: 150
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: "100%",
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 1100,
            options: {
              chart: {
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 700,
            options: {
              chart: {
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 500,
            options: {
              chart: {
                height: 350
              },
              legend: {
                show: false
              }
            }
          }],
          colors: colors
        };
      }

      const handleCancel = () => {
        setIsModalVisible(false);
        setTableDataInApi([])
      };

      const onHandaleViewGSM = (id) => {
        window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
      }

    return (
      <>
       <Modal title={<div className="d-flex"><h5 >{ModelId}</h5>{TableData.length > 0 ? <span className='btn btn-primary text-white ml-2' ><i className='fas fa-download'></i></span> : ''}{TableData.length > 0 ? <Link to={{ pathname: `All-GSM`, state: { detail: TableData.map((data) => (data?.UNIQUE_ID)), title: ModelId } }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link> : ''}</div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} >
        <div>
          <div className="modal-table">
            {ModalLoad ? (
              <div className="example">
                <Spin size="large" />
              </div>
            ) : <table className="table border text-center table-borderless" id="table-1">
              <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                <tr>
                  <th className="text-center">SR NO.</th>
                  <th >Company/Client Name</th>
                  <th>Modem Serial No</th>
                   </tr>
              </thead>
              <tbody id="tbody">
                <>
                  {TableData.length > 0 ?
                    TableData.map((data, id) => (
                      <tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} onClick={() => onHandaleViewGSM(data?.UNIQUE_ID)}>
                        <td className="text-center">{id + 1}</td>
                        <td>{data?.company_name}</td>
                        <td>{data?.modem_serial_no}</td>
                      </tr>
                    )) : <tr></tr>}
                </>
              </tbody>
            </table>}
            {TableData.length == 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}

          </div>
        </div>
      </Modal>
        <div className='tw-mt-5' >
           
            <div className='tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12' >
            <div className="md:tw-col-span-6 tw-col-span-12">
                    <Card className=' !tw-border-t-btninfo' >
                    <CardHeader IsDrag={false} title='Power Source' className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b tw-p-4 tw-px-6" tooltip={`Find Data In gsm_consumer_device_index (MMD_STATUS_PWR_SOURCE) 
                        If HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 1 OR ALERT_DISABLE.MMD_STATUS_PWR_SOURCE IS NUll OR  ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then External Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 1 AND HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 0 Then Battery Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then Batter Backup Not Available`}  >
                        <div className="d-flex tw-items-center tw-gap-2">
                         <h2>Power Source</h2>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <div
                          className="card-body"
                          style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
                          {PowerSource?.series?.length > 0 && PowerSource?.category?.length > 0 ? <ReactApexChart options={options1} type="pie" series={PowerSource?.series} height={options1.chart.height} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img className="loader_center text-center" src={loaderGif} /></div>}
                        </div>
                        <CardFooter className='tw-flex tw-justify-between tw-px-3 !tw-pb-0' >
                          <p className='!tw-m-0'>Total Count :  {sum(PowerSource?.series)}</p>
                          <p className='!tw-m-0'>{'Pie chart(Dynamic)'} </p>
                        </CardFooter>
                      </CardContent>
                    </Card>
                  {/* </DraggableHelper> */}
                </div>
            </div>
            {/* </div> */}
        </div>
        </>
    )
}

export const CardBody = ({ children, CardTitle, isBodyClass = true, className, bodyClassName }) => {
    return (
        <div className={`tw-text-card-foreground tw-shadow-md tw-w-full tw-my-4 tw-py-2 border tw-bg-card tw-rounded ${className ?? className}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }} >
            <div className='tw-flex tw-flex-col tw-space-y-1.5' >
                <h5 className='tw-font-semibold tw-leading-none tw-tracking-tight border-bottom tw-px-2 tw-py-3 tw-mb-0' >
                    {CardTitle}
                </h5>
            </div>
            <div className={`${isBodyClass ? "tw-grid tw-gap-1 tw-px-6 tw-pb-4" : ""} ${bodyClassName ?? bodyClassName}`} >
                {children}
            </div>
        </div>
    )
}
