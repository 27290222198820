import React, { useEffect, useState } from 'react'
import { Users } from 'react-feather'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import GeoLocation from '../../../Common/GecodeLocation'
import { activeandinactive, AddMechanicalDataINGSM, getAccount, getAllMeter, getAllModem, getArea, getAreaById, getAttachmentBymeterid, getCircle, getCircleById, getCountUseToConsumerInIMEI, getDivision, getDivisionById, getGa, getGaById, getGSMDataById, GetIMEIWiseModemData, getMechanicalByID, getMechanicalByMultipalID, getMeterByID, getMeterByMultipalID, getModemByID, GetModemTypeWiseIMEI, getProfilNameByAccountId, getRolesById, getSubdevision, getSubdevisionById, geturls3, InsertGsmData, InsertModemDetails, Table_Get_All_City_by_State, Table_Get_All_Country, Table_Get_All_State_by_Country, UpdateInGSMMeterData, UpdateInGSMmeterDataAndmeterTable, UpdateInGSMmodem, UpdateInGSMModemDataAndModemTable, Update_Gsm_consumer_Attachments, uploadsDeleteFile, uploadsFiles,UpdateConsumerStatusHistory,GetConsumerStatusHistoryByConsumerId ,ChangeStatusToPendding,ActivityLogMaintainOnFrontend} from '../../../Common/Utils';
import { Modal, Space, DatePicker } from 'antd'
import moment from 'moment'
// import MapPicker from 'react-google-map-picker';
import { ContryCodeData } from '../../Login/Contrycode';
import $, { data } from 'jquery'
import { toast } from 'react-toastify'
import { AttachmanyUrl, BootstrapTooltip, MeterUrl } from '../../../Common/common';
import swal from "sweetalert2";
import axios from 'axios';
import fileDownload from 'js-file-download'
import GoogleLoader from '../../../Common/Loaders/GoogleLoader';
import Select from 'react-select';
import TimeZonelist from '../../../Common/TimezoneList.json'
import '../Deshboard.css'
import { ReactComponent as UserIcon } from '../../../assets/SVGs/UserIcon.svg';

window.Swal = swal;
const DefaultLocation = { lat: null, lng: null };
const DefaultZoom = 10;

export default function ConsumerInfo(props) {
   const { GSMdata } = props
   const [country, setCountry] = useState("");
   const [state, setState] = useState("");
   const [city, setCity] = useState("");
   const [SubdevisionData, setSubdevisionData] = useState([])
   const [DivisionData, setDivisionData] = useState([])
   const [CircleData, setCircleData] = useState([])
   const [GaData, setGaData] = useState([])
   const [AccounData, setAccountData] = useState([])
   const [AreaData, setAreaData] = useState([])
   const [ModemData, setModemData] = useState([])
   const [MeterData, setMeterData] = useState([])
   const [Profile, setProfile] = useState({ account: '', ga: '', circle: '', division: '', subdivision: '', area: '' })
   const [MeterInputs, setMeterInputs] = useState({})
   const [ModemInputs, setModemInputs] = useState({})
   const [remark, setRemark] = useState('')
   const [Consumer, setConsumer] = useState({ cunsumerId: '', Installation: '', BPN: '' })
   const [ConsumerType, setConsumerType] = useState({ MeterId: GSMdata?.METER_TYPE || '', ModemId: GSMdata?.modem_type || '', IMEI: GSMdata?.imei || '', MSRNO: GSMdata?.modem_serial_no || '', PSNO: GSMdata?.panel_serial_no || '', MF: GSMdata?.MF || '',METERSRNO:GSMdata?.MECHENICAL_SERIAL_NUMBER || '',CONSUMER_TYPE:GSMdata?.CONSUMER_TYPE || '',CONVSERNUM:GSMdata?.CONVSERNUM||"",mechanicalId:GSMdata?.MECHANICAL_TYPE||"",TIMEZONE:GSMdata?.timezone,DATA_RETRIVAL:GSMdata?.DATA_RETRIVE_DATE})
   const [AccountInput, serAccountInput] = useState({ "address_compony_name": '', "address_building_no": "", "address_street_area": "", "address_landmark_colony": "", "address_longitude": "", "address_lattitude": "", "address_pin_code": "", "address_city_id": "", "address_state_id": "", "address_country_id": "" })
   const [contactDetails, setcontactDetails] = useState([])
   const [Attachment, setAttachment] = useState([]);
   const [installDetails, setinstallDetails] = useState({ "Installation_Person_First_Name": "", "Installation_Person_Last_Name": "", "Installation_Person_Email": "", "Installation_Person_Work_Number": "", "Installation_Person_Mobile_Number": "", "Installation_Person_Position": null, "Installation_Date_And_Time": "" })
   const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [location, setLocation] = useState(defaultLocation);
   const [zoom, setZoom] = useState(DefaultZoom);
   const [conCode, setConCode] = useState("+91");
   const [ContactconCode, setContactconCode] = useState("+91");
   const [scripcodeid, setScripcodeid] = useState('')
   const [disable, setDisable] = useState(false)
   const history = useNavigate()
   const params = useParams()
   const [permision, setpermision] = useState({})
	const [rolesGSMedit,setrolesGSMedit]=useState('false')
   const [isActivityModal,setisActivityModal]=useState(false)
   const [NameTab,setNameTab]=useState('Address')

	const [StatusHistory,setStatusHistory]=useState([])
   const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
   const [isModalImage, setisModalImage] = useState(false)
   const [imageName, setImageName] = useState('')
   const [isModalMeter, setisModalMeter] = useState(false)
   const [MeterDataAttach, setMeterDataAttach] = useState([])
	const [btnFlag, setBtnFlag] = useState(false)
	const [ModemFlag, setModemFlag] = useState(true)
	const [MeterFlag, setMeterFlag] = useState(true)
	const [MechanicalFlag, setMechanicalFlag] = useState(true)
   const [IMEIList,setIMEIList]=useState([])
	const [isModalModem,setisModalModem]=useState(false)
   const [options,setoptions]=useState([])
   const [stateData,setstateData]=useState([])
   const [Citydata,setCitydata]=useState([])
   const [ConsumerLoader,setConsumerLoader]=useState(false)
	const [MechanicalInputs,setMechanicalInputs]=useState({})
	const [MechanicalData, setMechanicalData] = useState([]);
	const [Dataretrivaldate, setDataretrivaldate] = useState(false)


   const getCountry=async()=>{
      const data=await Table_Get_All_Country()
      if(data.success==true){
          setoptions(data.data.data)
      }else{
          console.log('something want wrong')
      }
  }

  const getState=async(id)=>{
   const data=await Table_Get_All_State_by_Country(id)
   if(data.success==true){
      setstateData(data.data.data)
   }else{
       console.log('something want wrong')
   }
}

const getCity=async(id)=>{
   const data=await Table_Get_All_City_by_State(id)
   if(data.success==true){
      setCitydata(data.data.data)
   }else{
       console.log('something want wrong')
   }
}
  useEffect(()=>{
      getCountry()
  },[])
  useEffect(()=>{
   getState(AccountInput?.address_country_id)
},[AccountInput?.address_country_id])
useEffect(()=>{
   getCity(AccountInput?.address_state_id)
},[AccountInput?.address_state_id])

   const onHandaleRoles = async () => {
      const data = await getRolesById(userInfo?.role)
      if (data.success === true) {
         const obj = data.data.data[0]
         setpermision({
            ...obj,
            roles_permission_category0: JSON.parse(
              obj.roles_permission_category0||'{}'
            ),
            roles_permission_category5: JSON.parse(
              obj.roles_permission_category5||'{}'
            ),
            roles_permission_category2: JSON.parse(
              obj.roles_permission_category2||'{}'
            ),
            roles_permission_category3: JSON.parse(
              obj.roles_permission_category3||'{}'
            ),
            roles_permission_category1: JSON.parse(
              obj.roles_permission_category1||'{}'
            ),
            roles_permission_category4: JSON.parse(
              obj.roles_permission_category4||'{}'
            ),
          })
      } else {
         console.log('something want wrong')
      }
   }
   useEffect(() => {
      if (ConsumerType.MeterId !== '') {
         onHandaleMeter()
         setScripcodeid(ConsumerType.ModemId + '_' + ConsumerType.MeterId)
      } else {
         setMeterInputs({})
         setScripcodeid(ConsumerType.ModemId + '_' + ConsumerType.MeterId)

      }
   }, [ConsumerType.MeterId])

   const onHandaleMeter = async () => {
      const data = await getMeterByID(ConsumerType.MeterId)
      if (data.success === true) {
         data.data.data.length > 0 ? setMeterInputs(data.data.data[0]) : setMeterInputs({})

      } else {
         console.log('something want wrong')
      }
   }
   const handleChangeAttachment = async (e) => {
      const { name, value, id } = e.target;
      let updatedTask = [...Attachment];
      if (name === 'file') {
         var totalSizeMB = value.target.files[0].size / Math.pow(1024, 2)
         if (totalSizeMB >= 50) {
            swal.fire('', 'File size should be under 50 MB', 'error');
         } else {
            
            updatedTask[id][name] = value.target.files[0];
            updatedTask[id]["update_at"] = moment().format()
         }
      } else {
         if(name === 'description'){
            updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g,'');

         }else{
         updatedTask[id][name] = value.target.value.replace(/[\/\\"`\t\n]/g,'');
         }
      }
      setAttachment([...updatedTask]);
   };
   useEffect(() => {
      console.log(ConsumerType.MeterId);
      if (ConsumerType.ModemId !== '') {
         onHandaleModem()
         setScripcodeid(ConsumerType.ModemId + '_' + ConsumerType.MeterId)

      } else {
         setModemInputs({})
         setScripcodeid(ConsumerType.ModemId + '_' + ConsumerType.MeterId)

      }
   }, [ConsumerType.ModemId])

   const ongetGaById = async (id) => {
      const data1 = await getGaById(id)
      if (data1.success === true) {
         setGaData(data1.data.data)
      }
   }

   const ongetCircle = async (id) => {
      const data1 = await getCircleById(id)
      if (data1.success === true) {
         setCircleData(data1.data.data)
      }
   }
   const ongetSubdevisionById = async (id) => {
      const data1 = await getSubdevisionById(id)
      if (data1.success === true) {
         setSubdevisionData(data1.data.data)
      }
   }
   const ongetDivisionById = async (id) => {
      const data3 = await getDivisionById(id)
      if (data3.success === true) {
         setDivisionData(data3.data.data)
      }
   }
   const ongetArea = async (id) => {
      const data3 = await getAreaById(id)
      if (data3.success === true) {
         setAreaData(data3.data.data)
      }
   }

   const [NameObj,setNameObj]=useState({})
	const ongetProfilNameByAccountId=async(id)=>{
		const data=await getProfilNameByAccountId({ID:id})
		if(data.success===true){
		   setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
		}else{
		   console.log('something want wrong')
		}
	 }

    useEffect(()=>{
		if(ConsumerType.ModemId&&ConsumerType.IMEI&&ConsumerType.MSRNO){

		}else{
			setConsumerType({...ConsumerType,MeterId:GSMdata?.METER_TYPE||'',METERSRNO:GSMdata?.MECHENICAL_SERIAL_NUMBER||'',CONVSERNUM:GSMdata?.CONVSERNUM||'',mechanicalId:GSMdata?.MECHANICAL_TYPE||"" })
         setMeterFlag(false)
         setMechanicalFlag(false)
		}

	},[ConsumerType.ModemId,ConsumerType.IMEI,ConsumerType.MSRNO])
   useEffect(() => {
      if (Profile.account !== '') {
         ongetGaById(Profile.account)
         setProfile({ ...Profile, ga: '', circle: '', division: '', subdivision: '', area: '' })
         setCircleData([])
         setSubdevisionData([])
         setDivisionData([])
         setAreaData([])
         ongetProfilNameByAccountId(Profile.account)
      } else {
         setProfile({ ...Profile, ga: '', circle: '', division: '', subdivision: '', area: '' })
         setGaData([])
         setCircleData([])
         setSubdevisionData([])
         setDivisionData([])
         setAreaData([])
      }
   }, [Profile.account])

   useEffect(() => {
      if (Profile.ga !== '') {
         ongetCircle(Profile.ga)
         setProfile({ ...Profile, circle: '', division: '', subdivision: '', area: '' })
         setSubdevisionData([])
         setDivisionData([])
         setAreaData([])
      } else {
         setProfile({ ...Profile, circle: '', division: '', subdivision: '', area: '' })
         setCircleData([])
         setDivisionData([])
         setSubdevisionData([])
         setAreaData([])
      }
   }, [Profile.ga])

   useEffect(() => {
      if (Profile.circle !== '') {
         ongetDivisionById(Profile.circle)
         setProfile({ ...Profile, division: '', subdivision: '', area: '' })
         setAreaData([])
         setSubdevisionData([])
      } else {
         setProfile({ ...Profile, division: '', subdivision: '', area: '' })
         setAreaData([])
         setDivisionData([])
         setSubdevisionData([])
      }
   }, [Profile.circle])

   useEffect(() => {
      if (Profile.division !== '') {
         ongetSubdevisionById(Profile.division)
         setAreaData([])
         setProfile({ ...Profile, subdivision: '', area: '' })
      } else {
         setProfile({ ...Profile, subdivision: '', area: '' })
         setSubdevisionData([])
         setAreaData([])

      }
   }, [Profile.division])

   useEffect(() => {
      if (Profile.subdivision !== '') {
         ongetArea(Profile.subdivision)
         setProfile({ ...Profile, area: '' })
      } else {
         setProfile({ ...Profile, area: '' })
         setAreaData([])
      }
   }, [Profile.subdivision])

//    useEffect(()=>{
// console.log('consumer imei update',ConsumerType.IMEI)
//    },[ConsumerType.IMEI])

   const onHandaleModem = async () => {

      const data = await getModemByID(ConsumerType.ModemId)
      if (data.success === true) {
         data.data.data.length > 0 ? setModemInputs(data.data.data[0]) : setModemInputs({})
      } else {
         console.log('something want wrong')
      }
   }


   const onHandaleSelection = async () => {
      setConsumerLoader(true)
      const data = await getAccount()
      if (data.success === true) {
         setAccountData(data.data.data)
         const data6 = await getAllModem(true)
         if (data6.success === true) {
            setModemData(data6?.data?.data)
            setConsumerLoader(false)
         }
         
      } else {
         console.log('somthing want wrong')
         setConsumerLoader(false)

      }
   }
   const onGetMeterDetails=async(value)=>{
      const EVC_ID=ModemData.length>0&&ModemData.filter((item)=>item?.MODEM_UNIQUE_ID==value)[0]
      const data=await getMeterByMultipalID({meterId:JSON.parse(EVC_ID?.EVC_DRIVER||'[]')})
      if(data.success){
         setMeterData(data.data.data)
      }else{
      }
      }

   
   useEffect(()=>{
      if(GSMdata?.MODEM_TYPE){
      }
   },[ModemData])



   useEffect(() => {
      
      setModemFlag(false)
      setMeterFlag(false)
      setMechanicalFlag(false)
      setDataretrivaldate(false)
      onHandaleRoles()
      onHandaleSelection()
      setProfile({ account: GSMdata?.cat0_id || '', ga: GSMdata?.cat1_id || '', circle: GSMdata?.cat2_id || '', division: GSMdata?.cat3_id || '', subdivision: GSMdata?.cat4_id || '', area: GSMdata?.cat5_id || '' })
      setConsumerType({ MeterId: GSMdata?.METER_TYPE || '', ModemId: GSMdata?.modem_type || '', IMEI: GSMdata?.imei || '', MSRNO: GSMdata?.modem_serial_no || '', PSNO: GSMdata?.PANEL_SERIAL_NO || '', MF: GSMdata?.MF || '',METERSRNO:GSMdata?.MECHENICAL_SERIAL_NUMBER || '',CONSUMER_TYPE:GSMdata?.CONSUMER_TYPE || '',CONVSERNUM:GSMdata?.CONVSERNUM||'',TIMEZONE:GSMdata?.timezone  })
      serAccountInput(GSMdata?.address || {})

      setcontactDetails(GSMdata?.CONTACT_PERSON || [])
      setinstallDetails({ "Installation_Person_First_Name": GSMdata?.installation_details?.Installation_Person_First_Name||'', "Installation_Person_Last_Name": GSMdata?.installation_details?.Installation_Person_Last_Name||'', "Installation_Person_Email": GSMdata?.installation_details?.Installation_Person_Email||'', Installation_Person_Mobile_Number: GSMdata?.installation_details?.Installation_Person_Mobile_Number?.split(' ')[1], Installation_Person_Work_Number: GSMdata?.installation_details?.Installation_Person_Work_Number?.split(' ')[1] ,Installation_Person_Position:GSMdata?.installation_details?.Installation_Person_Position||'',Installation_Date_And_Time:GSMdata?.installation_details?.Installation_Date_And_Time||''} || {})
      setConsumer({ cunsumerId: GSMdata?.CUSTOMER_ID || '', Installation: GSMdata?.INSTALLATION_NUMBER || '', BPN: GSMdata?.BUSINESS_PARTNER_NUMBER || '' })
      setMeterInputs(GSMdata?.METER_DATA || {})
      setModemInputs(GSMdata?.MODEM_DATA || {})
      setRemark(GSMdata?.REMARKS || '')
      setAttachment(JSON.parse(GSMdata?.installation_attechments||'[]') || [])
      if(GSMdata?.modem_type!=''){
      onGetModemTypeWiseIMEI(GSMdata?.modem_type)
      }
      const groupObj={
         CATEGORY0:GSMdata?.cat0_id,
         CATEGORY1:GSMdata?.cat1_id,
         CATEGORY2:GSMdata?.cat2_id,
         CATEGORY3:GSMdata?.cat3_id,
         CATEGORY4:GSMdata?.cat4_id,
         CATEGORY5:GSMdata?.cat5_id
      }
      var flagDul=0
      if(userInfo?.role!='ROLE1650614500708'){
				Object.entries(groupObj).map(([key,value],id)=>{
					if(value===''){
						if(flagDul==0){
						const permit=JSON.parse(props?.permision['roles_permission_'+Object.entries(groupObj)[id-1][0]?.toLocaleLowerCase()]||'{}')
						setrolesGSMedit(permit[Object.entries(groupObj)[id-1][0]+'_edit']?.filter((item)=>(item==Object.entries(groupObj)[id-1][1])).length>0?'true':'false')
						flagDul=1
						}
						return true
					}
				})
         }
   }, [GSMdata]);

   const handleCancel = () => {
      setIsModalVisible(false);
      setLocation({ ...DefaultLocation })

   };
   function handleChangeLocation(lat, lng) {
      setLocation({ lat: lat, lng: lng });
      serAccountInput({ ...AccountInput, address_lattitude: lat, address_longitude: lng })
   }

   function handleChangeZoom(newZoom) {
      setZoom(newZoom);
   }

   const handleChangeTask = async (e) => {
      const { name, value, id } = e.target;
      let updatedTask = [...contactDetails];
      if (name === 'Work_no' || name === 'Mobile') {
         updatedTask[id][name] = value.target.value;
      } else {
         updatedTask[id][name] = value.target.value;
      }
      setcontactDetails([...updatedTask])
   };
   const onAdd = () => {
      const initial = { "Sr_No": contactDetails.length, "salutation": " ", "First_Name": "", "Last_Name": "", "Email": "", "Mobile": "", "Work_no": "", "Designation": "", "UserType": " " }
      // if(contactDetails.length>0){
      const task = contactDetails[contactDetails.length - 1];
      contactDetails[contactDetails.length] = initial
      setcontactDetails([...contactDetails])
      // }

   };

   const onDelete = (id) => {
      const test = [...contactDetails]
      test.splice(id, 1)
      setcontactDetails([...test])
   }
   const onAddAttachment = () => {
      const initial = { SRNO: Attachment.length+1, title: "", description: "", file: "", create_at: moment().format(), update_at: '' };
      // if(contactDetails.length>0){
      const task = Attachment[Attachment.length - 1];
      Attachment[Attachment.length] = initial;
      setAttachment([...Attachment]);
      // }
   };
   const onDeleteAttachment = async (id,file) => {
      swal.fire({
         title: "Are you sure?",
         text: "Once deleted, you will not be able to recover this Card!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            if(file!==''){
            const test = [...Attachment];
            console.log(id);
            const data = test.filter((item, ids) => (ids !== id))
            console.log(data)
            setAttachment([...data]);
            const data2= await uploadsDeleteFile({path:`All_Consumer_Attachments/${file}`})
        if(data2.success==true){
            const data1 = await Update_Gsm_consumer_Attachments({ INSTALLATION_ATTECHMENTS: JSON.stringify(data) }, GSMdata.unique_id)
            if (data1.success == true) {
              props.onConsumerUpdateDate()
            } else {
               toast.error('Server Error')
            }
         }else{
         toast.error('File Not Delete In Server')
      }
   }else{
      const test = [...Attachment];
      console.log(id);
      const data = test.filter((item, ids) => (ids !== id))
      console.log(data)
      setAttachment([...data]);
   }
}
      })
   };
   const onSubmitGsmDate = async (e) => {
      e.preventDefault()
      if (Profile.account !== '' ) {
         setBtnFlag(true)
            const obj = {
               DEVICE_SERIAL_NO: ConsumerType.MSRNO,
               METER_DATA: JSON.stringify({ "meter_make": MeterInputs?.METER_MAKE_NAME, "meter_protocol": '', "meter_software_protocol": MeterInputs?.METER_SOFTWARE_PROTOCOL_NAME, "meter_class": MeterInputs?.METER_CLASS_NAME, "meter_hardware_protocol": MeterInputs?.METER_HARDWARE_PROTOCOL_NAME, "meter_firmware": MeterInputs?.METER_FIRMWARE_NAME, "meter_type": MeterInputs?.METER_TYPE_NAME, "meter_categoy": MeterInputs?.METER_CATEGORY_NAME, "meter_modem": MeterInputs?.MODEM_TYPE_NAME, "MEMORY_STORAGE_IN_DAYS": MeterInputs?.MEMORY_STORAGE_IN_DAYS, "modem_model": MeterInputs?.METER_MODEL_NUMBER_NAME }),
               METER_TYPE: ConsumerType.MeterId,
               IMEI: ConsumerType.IMEI,
               MODEM_TYPE: ConsumerType.ModemId,
               MODEM_DATA: JSON.stringify({ "modem_make": ModemInputs?.MODEM_MAKE_NAME, "modem_sku": ModemInputs?.SKU_NAME, "modem_model": ModemInputs?.MODEM_MODEL_NAME, "modem_firmware": ModemInputs?.MODEM_FIRMWARE_NAME, "modem_hardware": ModemInputs?.MODEM_HARDWARE_NAME, "modem_profile_name": ModemInputs?.MODEM_PROFILE_NAME }),
               CAT0_ID: Profile.account,
               CAT1_ID: Profile.ga,
               CAT2_ID: Profile.circle,
               CAT3_ID: Profile.division,
               CAT4_ID: Profile.subdivision,
               CAT5_ID: Profile.area,
               ADDRESS: JSON.stringify(AccountInput),
               company_name:AccountInput?.address_compony_name,
               REMARKS: remark,
               CONTACT_PERSON: JSON.stringify(contactDetails),
               CUSTOMER_ID: Consumer.cunsumerId,
               add_by: userInfo.unique_id,
               SCRIPTDECODE_ID: scripcodeid,
               PANEL_SERIAL_NO: ConsumerType.PSNO,
               LATITUDE: AccountInput.address_lattitude,
               LONGITUDE: AccountInput.address_longitude,
               INSTALLATION_NUMBER: Consumer.Installation,
               BUSINESS_PARTNER_NUMBER: Consumer.BPN,
               INSTALLATION_DETAILS: JSON.stringify({ ...installDetails, Installation_Person_Work_Number: conCode + ' ' + installDetails.Installation_Person_Work_Number, Installation_Person_Mobile_Number: conCode + ' ' + installDetails.Installation_Person_Mobile_Number }),
               MF: ConsumerType?.MF,
					MECHENICAL_SERIAL_NUMBER:ConsumerType.METERSRNO,
               Change_Script_id:scripcodeid==(GSMdata?.MODEM_TYPE+'_'+GSMdata?.METER_TYPE)?false:true,
               CONSUMER_TYPE:ConsumerType?.CONSUMER_TYPE,
               TIMEZONE:ConsumerType?.TIMEZONE
            }

            const old_json={
               CAT0_ID:GSMdata?.cat0_id,
				CAT1_ID:GSMdata?.cat1_id,
				CAT2_ID:GSMdata?.cat2_id,
				CAT3_ID:GSMdata?.cat3_id,
				CAT4_ID:GSMdata?.cat4_id,
				CAT5_ID:GSMdata?.cat5_id,
				ADDRESS:JSON.stringify(GSMdata?.address),
            company_name:GSMdata?.company_name,
            LATITUDE:GSMdata?.latitude,
				LONGITUDE:GSMdata?.longitude,
            TIMEZONE:GSMdata?.timezone
            }
            var old_diffrent_json={}
            const diffrent_json={}
            Object.keys(old_json).map((item)=>{
               if(old_json[item]!=obj[item]){
                  diffrent_json[item]=obj[item]
                  old_diffrent_json[item]=old_json[item]
               }
            })

            const data = await UpdateInGSMmodem({...obj,old_json:JSON.stringify(old_diffrent_json),new_json:JSON.stringify(diffrent_json),created_by:userInfo.unique_id}, GSMdata?.unique_id)
            if (data.success === true) {
               toast.success('Update Gsm Successfully')
               props.onConsumerUpdateDate()
               setDisable(false)
         setBtnFlag(false)
            } else if (data.err.response.status === 500) {
               toast.error('Not Allow Duplicate Fill')
         setBtnFlag(false)
            }
            else {
               toast.error('Something Wait Wrong')
         setBtnFlag(false)
            }
         } else {
            toast.error('Something Wait Wrong')
         setBtnFlag(false)
         }
     
   }

   const oninactive = async () => {
      swal.fire({
         title: "Are You Sure InActive Gsm Modem?",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            swal.fire({
               title: 'Notes',
               input: 'text',
               inputAttributes: {
                 autocapitalize: 'off'
               },
               showCancelButton: true,
               confirmButtonText: 'Submit',
               showLoaderOnConfirm: true,
               preConfirm: (data) => {
                  if(data){
                 return data
                  }else{
                     swal.showValidationMessage(
                        `Please Enter Notes`
                      )
                  }
               },
               allowOutsideClick: () => !swal.isLoading()
             }).then(async(result) => {
                console.log(result)
                if (result.isConfirmed) {
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.modem_status == 1 ? 'Active' : GSMdata?.modem_status == 0 ? 'InActive' : GSMdata?.modem_status == 2 ? 'Under Maintenance' : GSMdata?.modem_status == 3 ? 'Stand By' : 'Pending Configration'} To InActive`}},GSMdata.unique_id)
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_status', value: '0', matchId: 'UNIQUE_ID' }, GSMdata?.unique_id)
            const data1 = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_sub_status', value: '3', matchId: 'UNIQUE_ID' }, GSMdata?.unique_id)
            if (data.success === true) {
               toast.success('Success Inactive GSM Modem')
               history(`/view_existing_consumer_gsm/${GSMdata.unique_id}`)
            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }
   const onactive = async () => {
      if(GSMdata?.modem_status == 0){
         swal.fire({
            title: "Are You Sure Active GSM Modem?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
               swal.fire({
                  title: 'Notes',
                  input: 'text',
                  inputAttributes: {
                    autocapitalize: 'off'
                  },
                  showCancelButton: true,
                  confirmButtonText: 'Submit',
                  showLoaderOnConfirm: true,
                  preConfirm: (data) => {
                     if(data){
                    return data
                     }else{
                        swal.showValidationMessage(
                           `Please Enter Notes`
                         )
                     }
                  },
                  allowOutsideClick: () => !swal.isLoading()
                }).then(async(result) => {
                   console.log(result)
                   if (result.isConfirmed) {
                     await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.modem_status == 1 ? 'Active' : GSMdata?.modem_status == 0 ? 'InActive' : GSMdata?.modem_status == 2 ? 'Under Maintenance' : GSMdata?.modem_status == 3 ? 'Stand By' : 'Pending Configration'} To Pending Configration`}},GSMdata.unique_id)
               const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_status', value: '-1', matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
               if (data.success === true) {
                  toast.success('Success Active GSM Modem')
                  history(`/view_existing_consumer_gsm/${GSMdata.unique_id}`)
               } else {
                  toast.error('something want wrong')
               }
            }
         })
            }
         })
      }else{
      swal.fire({
         title: "Are You Sure Active GSM Modem?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            swal.fire({
               title: 'Notes',
               input: 'text',
               inputAttributes: {
                 autocapitalize: 'off'
               },
               showCancelButton: true,
               confirmButtonText: 'Submit',
               showLoaderOnConfirm: true,
               preConfirm: (data) => {
                  if(data){
                 return data
                  }else{
                     swal.showValidationMessage(
                        `Please Enter Notes`
                      )
                  }
               },
               allowOutsideClick: () => !swal.isLoading()
             }).then(async(result) => {
                console.log(result)
                if (result.isConfirmed) {
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.modem_status == 1 ? 'Active' : GSMdata?.modem_status === 0 ? 'InActive' : GSMdata?.modem_status == 2 ? 'Under Maintenance' : GSMdata?.modem_status == 3 ? 'Stand By' : 'Pending Configration'} To Active`}},GSMdata.unique_id)
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_status', value: '1', matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
            if (data.success == true) {
               toast.success('Success Active GSM Modem')
               history(`/view_existing_consumer_gsm/${GSMdata.unique_id}`)
            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }
   }
   const onUnderMaintenance = async () => {
      swal.fire({
         title: 'Notes',
         input: 'text',
         inputAttributes: {
           autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (data) => {
            if(data){
           return data
            }else{
               swal.showValidationMessage(
                  `Please Enter Notes`
                )
            }
         },
         allowOutsideClick: () => !swal.isLoading()
       }).then(async(result) => {
          console.log(result)
          if (result.isConfirmed) {
            await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.modem_status == '1' ? 'Active' : GSMdata?.modem_status == '0' ? 'InActive' : GSMdata?.modem_status == '2' ? 'Under Maintenance' : GSMdata?.modem_status == '3' ? 'Stand By' : 'Pending Configration'} To Under Maintenance`}},GSMdata.unique_id)

      swal.fire({
         title: "Consumer is under maintenance",
         icon: "warning",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_status', value: '2', matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Active GSM Modem')
               history(`/view_existing_consumer_gsm/${GSMdata.unique_id}`)
            } else {
               toast.error('something want wrong')
            }
         }
      })
   }
})
   }

   const onPenddingConfguration = async () => {
      swal.fire({
         title: "Are You Sure Pending Configuration GSM Modem?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      swal.fire({
         title: 'Notes',
         input: 'text',
         inputAttributes: {
           autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (data) => {
            if(data){
           return data
            }else{
               swal.showValidationMessage(
                  `Please Enter Notes`
                )
            }
         },
         allowOutsideClick: () => !swal.isLoading()
       }).then(async(result) => {
         console.log(result)
                if (result.isConfirmed) {
      swal.fire({
         title: "Comsumer is Pending Configuration",
         icon: "success",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.modem_status == 1 ? 'Active' : GSMdata?.modem_status == 0 ? 'InActive' : GSMdata?.modem_status == 2 ? 'Under Maintenance' : GSMdata?.modem_status == 3 ? 'Stand By' : 'Pending Configuration'} To Pending Configuration`}},GSMdata.unique_id)
      
            const data = await ChangeStatusToPendding(GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Pending Configuration GSM Modem')
               props.onConsumerUpdateDate()
               setDisable(false)
            } else {
               toast.error('Something Went Wrong')
            }
         }
      })
         }
      })
   }
})
   }

   const onOnline = async () => {
      swal.fire({
         title: "Are You Sure Change Consumer Status In Live?",
         icon: "warning",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            swal.fire({
               title: 'Notes',
               input: 'text',
               inputAttributes: {
                 autocapitalize: 'off'
               },
               showCancelButton: true,
               confirmButtonText: 'Submit',
               showLoaderOnConfirm: true,
               preConfirm: (data) => {
                  if(data){
                 return data
                  }else{
                     swal.showValidationMessage(
                        `Please Enter Notes`
                      )
                  }
               },
               allowOutsideClick: () => !swal.isLoading()
             }).then(async(result) => {
                console.log(result)
                if (result.isConfirmed) {
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Active Sub Status From ${GSMdata?.modem_sub_status == 1 ? 'Live' : GSMdata?.modem_sub_status == 0 ? 'InActive' : GSMdata?.modem_sub_status == 2 ? 'Temporary Disconnection' : GSMdata?.modem_sub_status == 3 ? 'Permanent Disconnection' : 'Pending Configration'} To Live`}},GSMdata.unique_id)
      
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_sub_status', value: '1', matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Change Consumer Status In Live Success')
               props.onConsumerUpdateDate()
         setDisable(false)

            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }
   const onTemporaryDisconnection = async () => {
      swal.fire({
         title: "Are You Sure Change Consumer Status In Temporary Disconnection",
         icon: "warning",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            swal.fire({
               title: 'Notes',
               input: 'text',
               inputAttributes: {
                 autocapitalize: 'off'
               },
               showCancelButton: true,
               confirmButtonText: 'Submit',
               showLoaderOnConfirm: true,
               preConfirm: (data) => {
                  if(data){
                 return data
                  }else{
                     swal.showValidationMessage(
                        `Please Enter Notes`
                      )
                  }
               },
               allowOutsideClick: () => !swal.isLoading()
             }).then(async(result) => {
                console.log(result)
                if (result.isConfirmed) {
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo.unique_id,notes:result?.value,status_activity:`Update Active Sub Status From ${GSMdata?.modem_sub_status == 1 ? 'Live' : GSMdata?.modem_sub_status == 0 ? 'InActive' : GSMdata?.modem_sub_status == 2 ? 'Temporary Disconnection' : GSMdata?.modem_sub_status == 3 ? 'Permanent Disconnection' : 'Pending Configration'} To Temporary Disconnection`}},GSMdata.unique_id)
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'modem_sub_status', value: '2', matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Change Consumer Status In Temporary Disconnection Success')
               props.onConsumerUpdateDate()
               setDisable(false)
            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }
   function formatBytes(bytes) {
      var marker = 1024; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB

      // return bytes if less than a KB
      if (bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB
      else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
  }
   const [loadUpload,setloadUpload]=useState(false)
   const onUploadFile=async(id)=>{
      setloadUpload(true)
      var attechmentsobj=[]
            const formData = new FormData()
            formData.append('Images', Attachment[id].file)
            const uploadImges=await uploadsFiles(formData,GSMdata.unique_id)
            if(uploadImges.success===true){
               Attachment[id].file=uploadImges?.data?.originalname
               Attachment[id].file_size=formatBytes(uploadImges?.data?.size)
               const data=await Update_Gsm_consumer_Attachments({INSTALLATION_ATTECHMENTS:JSON.stringify(Attachment)},GSMdata.unique_id)
               if(data.success==true) {
                  // ongetmeter_update_data()
                  toast.success('File Successfully Upload')
                  props.onConsumerUpdateDate()
      setloadUpload(false)

               }
            }else{
               toast.error('File Not Uploaded')
      setloadUpload(false)

               // attechmentsobj.push({...item,file:''})
            }
       }

   const onDownloadFiles = async (name) => {
       const data1=await geturls3({bucketName:'iot.memighty.com',objectKey:`All_Consumer_Attachments/${name}`, expiration:60})
     console.log(data1);
     var link = document.createElement("a");
     link.href = data1.data.data;
     link.download = name;
     link.click();

   }
   const [UrlImg,setUrlImg]=useState('')
   const onViewAttachments=async(e,name)=>{
      // e.preventDefault()

      setisModalImage(true) 
      const data1=await geturls3({bucketName:'iot.memighty.com',objectKey:`All_Consumer_Attachments/${name}`, expiration:60})
      setImageName(name)
      setUrlImg(data1.data.data)

     }

   const onMeterAttachment = async () => {
      const data = await getAttachmentBymeterid(ConsumerType?.MeterId)
      if (data.success === true) {
         setMeterDataAttach(data.data.data)
         setisModalMeter(true)
      } else {
         console.log('something want wrong');
      }
   }

   const onDownloadFilesMeter = async (name) => {
      const data1=await geturls3({bucketName:'iot.memighty.com',objectKey:`Meter_Driver_Attachments/${name}`, expiration:60})
		console.log(data1);
		var link = document.createElement("a");
		link.href = data1.data.data;
		link.download = name;
		link.click();
   }
   const onGetModemTypeWiseIMEI=async(value)=>{
		if(value!==''){
		setConsumerType({...ConsumerType,ModemId:value})
		const data=await GetModemTypeWiseIMEI(value)
		if(data.success===true){
			setIMEIList(data.data.data.map((item)=>({IMEI:item?.C4_IMEI,assign_length:item?.C9_CONSUMER_ASSIGN})))

		}else{
			console.log('something want wrong')
		}
	}else{
		setIMEIList([])
		setConsumerType({...ConsumerType,MSRNO:'',PSNO:'',IMEI:'',ModemId:value})
	}
	}

   const onGetIMEIWiseModemData=async(value)=>{
      if(value==='open'){
			setisModalModem(true)
	}else{
		if(value!==''){
			const dataCount=await getCountUseToConsumerInIMEI(value,{update:true,ID:GSMdata.unique_id})
			if(dataCount.success){
				console.log(dataCount,dataCount?.data?.data >= IMEIList.filter((item)=>(item?.IMEI===value))[0]?.assign_length);
				if(IMEIList.filter((item)=>(item?.IMEI===value))[0]?.assign_length!==''){
				if(dataCount?.data?.data >= IMEIList.filter((item)=>(item?.IMEI===value))[0]?.assign_length){
					swal.fire('', `This IMEI Already Assign ${IMEIList.filter((item)=>(item?.IMEI===value))[0]?.assign_length} Consumer`, 'error');
	}else{
		const data=await GetIMEIWiseModemData(value)
		if(data.success===true){
			setConsumerType({...ConsumerType,IMEI:value,MSRNO:data?.data?.data[0]?.C5_DEVICE_SERIAL_NO,PSNO:data?.data?.data[0]?.C6_PANEL_SERIAL_NO})
		}else{
			console.log('something want wrong')
		}
		
	}
	}else{
		
		swal.fire('', `This IMEI Not Set Max Assign Consumer Value`, 'error');
	}
}
	}else{
		setConsumerType({...ConsumerType,MSRNO:'',PSNO:'',IMEI:''})
	}
}
	}
		
	
   var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
   var imageExtensions = /(\.mp4|\.webm|\.ogg|\.mkv|\.mpg|\.m4v|\.m4p |\.mpv|\.vob|\.mpeg)$/i;
   const [dropValue, setDropValue] = useState('Active');


   const onHandalUpdatedataRetrivalDate = async () => {

      const obj={
         DATA_RETRIVAL:moment.utc(ConsumerType?.DATA_RETRIVAL).format('YYYY-MM-DD HH:mm:ss')
      }

      const old_json={
         DATA_RETRIVAL:moment.utc(GSMdata?.DATA_RETRIVE_DATE).format('YYYY-MM-DD HH:mm:ss')
      }
      var old_diff_json={}
      var new_diff_json={}

      Object.keys(old_json).map((item)=>{
         if(old_json[item]!=obj[item]){
            old_diff_json[item]=old_json[item]
            new_diff_json[item]=obj[item]
         }
      })
            const data = await activeandinactive({ tableName: 'globle_gsm_modem_list', colName: 'DATA_RETRIVE_DATE', value: `'${ConsumerType?.DATA_RETRIVAL}'`, matchId: 'UNIQUE_ID' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Update Data Retrival Date')
               props.onConsumerUpdateDate()
               setDataretrivaldate(false)
               ActivityLogMaintainOnFrontend({tableName:'ACT_'+GSMdata.unique_id,old_json:JSON.stringify(old_diff_json),new_json:JSON.stringify(new_diff_json),created_by:userInfo.unique_id})
            } else {
               toast.error('something want wrong')
            }
   }

  
   const onUpdateInGSMModemDataAndModemTable=async()=>{
      
      // if(ConsumerType?.ModemId){
      //    var ErrorStr=''
      //    if(ConsumerType?.IMEI==""&&ConsumerType?.MSRNO==""){
      //       setBtnFlag(false)
      //       return toast.error('Please Input IMEI And Modem Serial No')
      //    }else if(ConsumerType?.MSRNO==""){
      // setBtnFlag(false)
      //       return toast.error('Please Input Modem Serial No')
      //    }else if(ConsumerType?.IMEI==""){
      // setBtnFlag(false)
      //       return toast.error('Please Input IMEI')
      //    }
      // }
      const obj = {
         DEVICE_SERIAL_NO: ConsumerType.MSRNO,
         IMEI: ConsumerType.IMEI,
         MODEM_TYPE: ConsumerType.ModemId,
         MODEM_DATA: JSON.stringify({ "modem_make": ModemInputs?.MODEM_MAKE_NAME, "modem_sku": ModemInputs?.SKU_NAME, "modem_model": ModemInputs?.MODEM_MODEL_NAME, "modem_firmware": ModemInputs?.MODEM_FIRMWARE_NAME, "modem_hardware": ModemInputs?.MODEM_HARDWARE_NAME, "modem_profile_name": ModemInputs?.MODEM_PROFILE_NAME }),SCRIPTDECODE_ID: scripcodeid,
         PANEL_SERIAL_NO: ConsumerType.PSNO,
         Change_Script_id:scripcodeid==(GSMdata?.MODEM_TYPE+'_'+GSMdata?.METER_TYPE)&&GSMdata?.METER_TYPE?false:true,
         table_create_query:ModemData?.filter((item)=>item?.MODEM_UNIQUE_ID==ConsumerType.ModemId)[0]?.table_create_query||'{}'
      }

      const old_json = {
         DEVICE_SERIAL_NO: GSMdata.DEVICE_SERIAL_NO,
         IMEI: GSMdata.IMEI,
         MODEM_TYPE: GSMdata.MODEM_TYPE,
         PANEL_SERIAL_NO: GSMdata.PANEL_SERIAL_NO,
      }
      
      var old_diff_json={}
      var new_diff_json={}

      Object.keys(old_json).map((item)=>{
         if(old_json[item]!=obj[item]){
            old_diff_json[item]=old_json[item]
            new_diff_json[item]=obj[item]
         }
      })

      const data=await UpdateInGSMModemDataAndModemTable({...obj,old_json:JSON.stringify(old_diff_json),new_json:JSON.stringify(new_diff_json),created_by:userInfo.unique_id},GSMdata.unique_id)
      if(data.success==true){
         props.onConsumerUpdateDate()
         toast.success('successfully Modem Update')
         setModemFlag(false)
      }else if (data.err.response.status === 501) {
         toast.error("Not Allow Duplicate Fill");
         setBtnFlag(false)
      }else{
         toast.error('something want wrong')
      }
   }
   const [flagBtn, setflegBtn] = useState(false);
	const [inputs,setInputs]=useState({modemId:'',IMEI:'',DEVICE_SERIAL_NO:'',PANEL_SERIAL_NO:'',CREATED_BY:userInfo.unique_id,Consumer_assign:''})
	const [Validation,setValidation]=useState({})
   const validation = (name, value) => {
		switch (name) {
			case "modemId":
				if (!value) {
					return "Please input modemId!";
				} else {
					return "";
				}
			case "IMEI":
				if (!value) {
					return "Please input IMEI!";
				} else {
					return "";
				}
			case "DEVICE_SERIAL_NO":
				if (!value) {
					return "Please input Last DEVICE SERIAL NO!";
				} else {
					return "";
				}
			case "FirstName":
				if (!value) {
					return "Please input First Name!";
				} else {
					return "";
				}
		   
			default: {
				return null;
			}
		}
	};
	const onsubmitHandale=async(e)=>{
        e.preventDefault()
        var allErrors={}
              setflegBtn(true)
              Object.keys(inputs).forEach((key) => {
                const error = validation(key, inputs[key]);
                if (error && error.length) {
                    allErrors[key] = error;
                }
            });
              if (Object.keys(allErrors).length) {
                setflegBtn(false)
                return setValidation(allErrors);
            } else {
            
    const data=await InsertModemDetails(inputs)
    if(data.success===true){
		const data=await GetModemTypeWiseIMEI(ConsumerType?.ModemId)
		if(data.success===true){
			setIMEIList(data.data.data.map((item)=>({IMEI:item?.C4_IMEI,assign_length:item?.C9_CONSUMER_ASSIGN})))
		}else{
			console.log('something want wrong')
		}
        toast.success('Success Add Modem Details')
    setflegBtn(false)
    setisModalModem(false)
    }else if (data.err.response.status === 409) {
        toast.error("Duplicat Value Not Allow");
        setflegBtn(false)

    }else{
        toast.error('Something Want Wrong')
    setflegBtn(false)
    }

            }
      }
      const [CheckTableModal, setCheckTableModal] = useState(false);
      const [msgChecktable,setmsgChecktable]=useState('')

      const onCheckTable=()=>{
         setCheckTableModal(true)
         if(MeterInputs?.EVC==1){
            if(GSMdata?.CONVSERNUM!=''){
               if(Object.keys(GSMdata?.UNIQUE_TABLE_NAME||{}).length>0){
                  if(GSMdata?.UNIQUE_TABLE_NAME?.instantaneous?.split("_")[2]){
                        if(GSMdata?.UNIQUE_TABLE_NAME?.instantaneous?.split("_")[2]==GSMdata?.CONVSERNUM.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')){
                           console.log("Table Exist")
                           setmsgChecktable('1')
                        }else{
                           console.log("Table Not Exist")
                           setmsgChecktable('0')
                        }
                  }else{
                     console.log("Table Not Exist")
                     setmsgChecktable('0')
                  }
               }else{
                  console.log("Table Not Exist")
                  setmsgChecktable('0')
               }
            }else{
               console.log("Configuration Pending")
               setmsgChecktable('-1')
            }
         }else{
if(GSMdata?.MECHENICAL_SERIAL_NUMBER!=''){

   if(Object.keys(GSMdata?.UNIQUE_TABLE_NAME||{}).length>0){
      if(GSMdata?.UNIQUE_TABLE_NAME?.instantaneous?.split("_")[2]){
            if(GSMdata?.UNIQUE_TABLE_NAME?.instantaneous?.split("_")[2]==GSMdata?.MECHENICAL_SERIAL_NUMBER.trim().replaceAll(/[^A-Z a-z 0-9]/g, '')){
               console.log("Table Exist")
               setmsgChecktable('1')
            }else{
               console.log("Table Not Exist")
               setmsgChecktable('0')
            }
      }else{
         console.log("Table Not Exist")
         setmsgChecktable('0')
      }
   }else{
      console.log("Table Not Exist")
      setmsgChecktable('0')
   }
            }else{
      console.log("Configuration Pending")
      setmsgChecktable('-1')
            }
         }
      }

      const onHandalCancel=()=>{
         props.onConsumerUpdateDate()
         setDisable(false)
      }

      const onGetConsumerStatusHistoryByConsumerId=async()=>{
         const data=await GetConsumerStatusHistoryByConsumerId(GSMdata.unique_id)
         if(data.success==true){
            setStatusHistory(JSON.parse(data?.data?.data[0]?.CHANGE_STATUS_HISTORY)||[])
            setisActivityModal(true)
         }else{console.log('Something Went Wrong')}
      }
   return (
      <>

      <Modal title="" visible={isActivityModal} footer='' onCancel={() => {setisActivityModal(false)}} width={800}>
            
            <div className="comment-feedback-container">
                     <div className="comment-container">
                      <div className="comments-list">
                        {StatusHistory.map((comment,id) => (
                         comment.created_at? <div className="comment-list" key={id}>
                            <div className="tw-flex tw-mb-2 tw-gap-4">
                              <div className='tw-mt-2'>
                                <UserIcon className='tw-w-10 tw-h-10 tw-text-indigo-500' />
                              </div>
                              <div>
                                <div className="comment-body">
                                  <b >{comment.created_by}</b>
                                  <p className='tw-text-black'>{comment.status_activity}</p>
                                  <p className='tw-text-gray-600'>{comment.notes}</p>
                                </div>
                                <div className="comment-footer">
                                  <p className='tw-text-gray-500'>{moment.utc(comment.created_at).add(moment().utcOffset(), "m").format('DD MMM YYYY hh:mm:ss A')} ({moment.utc(comment.created_at).fromNow('ss:hh:mm')})</p>
                                </div>
                              </div>
                            </div> 
                          </div>:''
                        ))}
                      </div>
                    </div>
                    </div>
                        </Modal>
      <Modal  visible={CheckTableModal} footer='' maskClosable={false} onCancel={() => {setCheckTableModal(false)
      setmsgChecktable('')}} width={500}>
    <h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">{msgChecktable=='1'?'Table Already Created':msgChecktable=='0'?'Table Not Created':msgChecktable=='-1'?'Configuration Pendding':''}</h5>
    {/* <h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!CommandSchedStatus?.STATUS&&CommandSchedStatus?.STATUS!='Added In Buffer'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:CommandSchedStatus?.STATUS!='Added In Buffer'?<i className='fas fa-times mr-2' style={{color:'red'}}></i>:<i className='fas fa-check mr-2' style={{color:'green'}}></i>}{!CommandSchedStatus?.STATUS?'Command Pending':CommandSchedStatus?.STATUS}</h5> */}
   
               <div class="text-center" style={{marginTop:"20px"}}>
              {/* { msgChecktable=='0'?<input type="button" class="btn btn-danger " value="Create Table" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={()=>onUpdateInGSMmeterDataAndmeterTable()}/>: <input type="button" class="btn btn-danger " value="Close" id="meter_data_mqtt_cancel_button" name="meter_data_mqtt_cancel_button" onClick={()=>setCheckTableModal(false)}/>} */}
               </div>
    </Modal>
<Modal title='Add IMEI' visible={isModalModem} footer=''  onCancel={() => setisModalModem(false)} width={1000}>
			<form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onsubmitHandale}>
                                    <div class="form-row" style={{marginLeft:"10px"}}>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                            <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e)=>(setInputs({...inputs,modemId:e.target.value}))} value={inputs.modemId} >
                                                <option value="">Select Modem</option>
                                                {ModemData&&ModemData.map((item)=>(<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}       
                                                                                            </select>
                                                                                            <span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.modemId}
														</span>
                                        </div>
                                        <div class="form-group col-md-3">
														<label for="inputPassword4">
															IMEI:<span class="text-danger">*</span>
														</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="IMEI"
															maxlength="16"
															onChange={(e)=>(setInputs({...inputs,IMEI:e.target.value}))} 
                                                            value={inputs.IMEI} 
                                                            
														/>
                                                        <div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.IMEI}
														</div>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate IMEI Value
														</span>
													</div>
													<div class="form-group col-md-3">
														<label for="inputPassword4">
                                                        Device Serial No:<span class="text-danger">*</span>
														</label>
														<input
															class="form-control "
															id="consmrid"
															required=""
															name="consmrid"
															type="text"
															placeholder="Modem Serial No"
															onChange={(e)=>(setInputs({...inputs,DEVICE_SERIAL_NO:e.target.value}))} 
                                                            value={inputs.DEVICE_SERIAL_NO} 
                                                           
														/>
                                                         <div class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															 {Validation?.DEVICE_SERIAL_NO}
														</div>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate Modem Serial No
														</span>
													</div>
													<div class="form-group col-md-3">
														<label for="inputPassword4">Panel Serial No:</label>
														<input
															class="form-control"
															id="panelserialno"
															name="panelserialno"
															type="text"
															placeholder="Panel Serial No"
															onChange={(e)=>(setInputs({...inputs,PANEL_SERIAL_NO:e.target.value}))} 
                                                            value={inputs.PANEL_SERIAL_NO} 
                                                          
														/>
														<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Not Allow Duplicate Panel Serial No
														</span>
													</div>
                                                    <div class="form-group col-md-3">
														<label for="inputPassword4">
															Max Consumer Assign:
														</label>
														<input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder=""
															maxlength="16"
															onChange={(e)=>(setInputs({...inputs,Consumer_assign:e.target.value}))} 
                                                            value={inputs.Consumer_assign} 
                                                          
														/>
														
													</div>
                                    </div>
                                   
                                    <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                    <div class="text-left">
                                            <input type="submit" name="submit" id="submitvn" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={'Submit'}/>
                                        <Link class="btn btn-light text-dark" onClick={() => setisModalModem(false)}>Cancel</Link>  
                                    </div>
                                </form>
			</Modal>
         <Modal title="" visible={isModalImage} footer='' maskClosable={false}  onCancel={() => {setisModalImage(false)
         setImageName('')
         setUrlImg('')
         var video = document.getElementById("videos");
         if(video){
         video?.pause()
         }
         }} width={800}>
            {allowedExtensions.exec(imageName) ?
               <img src={`${UrlImg}`} height={500} width={"100%"}></img> : imageExtensions.exec(imageName) ? <video height={500} id='videos' width={"100%"} controls><source src={`${UrlImg}`} type="video/mp4" /><source src={`${UrlImg}`} type="video/ogg" /></video> : ''
            }
         </Modal>
         <Modal title="Map" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} onCancel={handleCancel} width={800}>
            {/* <MapPicker defaultLocation={{ lat: parseFloat(location?.lat || '19.506765954448635'), lng: parseFloat(location?.lng || '76.46875000000001') }}
               zoom={zoom}
               mapTypeId="roadmap"
               style={{ height: '500px' }}
               onChangeLocation={handleChangeLocation}
               onChangeZoom={handleChangeZoom}
               apiKey='AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s' /> */}
         </Modal>
         <Modal title="EVC Attachments" visible={isModalMeter} footer='' onCancel={() => (setisModalMeter(false))} width={1000}>
            {<div className="table-responsive">
               <table id="table_id" className="table table-striped">
                  <thead>
                     <tr>
                        <th className="text-center" >Unique No</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>File Name</th>
                        <th style={{ width: '100px' }}>Create At</th>
                        <th style={{ width: '100px' }}>Update At</th>
                        <th>Download</th>
                     </tr>
                  </thead>
                  <tbody>
                     {MeterDataAttach.length > 0 && MeterDataAttach.map((item, id) => (
                        <tr className="trheigth" >
                           <td>{item?.SRNO}</td>
                           <td>{item?.title}</td>
                           <td>{item?.description}</td>
                           <td>{item?.file}</td>
                           <td>{item?.create_at === '' ? '' : moment.utc(item?.create_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                           <td>{item?.update_at === '' ? '' : moment.utc(item?.update_at).format('DD/MM/YYYY hh:mm:ss A')}</td>
                           <td><a class="btn btn-warning text-white" onClick={() => { onDownloadFilesMeter(item?.file) }} download="" target={'_blank'}><i class="fa fa-download" aria-hidden="true"></i></a></td>
                        </tr>
                     ))}


                  </tbody>
               </table>
            </div>}
         </Modal>
         <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                      {ConsumerLoader ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                        <GoogleLoader bodyClassName={'tw-h-[70%]'} />
                    </div>:<>  <div class="card-header">
                           <h4>Update GSM Consumers</h4>
                           <div class="card-header-action">
                           {rolesGSMedit==='true'||userInfo?.role=='ROLE1650614500708'?<div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>:''}
                              {rolesGSMedit==='true'||userInfo?.role=='ROLE1650614500708'?<div class="dropdown">
                                 <button class={`btn ${GSMdata?.modem_status == 1 ? 'btn-success' : GSMdata?.modem_status == 0 ? 'btn-danger' : GSMdata?.modem_status == 2 ? 'btn-info' : GSMdata?.modem_status == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {GSMdata?.modem_status == 1 ? 'Active' : GSMdata?.modem_status == 0 ? 'InActive' : GSMdata?.modem_status == 2 ? 'Under Maintenance' : GSMdata?.modem_status == 3 ? 'Stand By' : 'Pending Configration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {GSMdata?.modem_status != 1&&GSMdata?.modem_status != -1 ? <a class="dropdown-item text-success" onClick={() => (onactive())} >Active</a> : ''}
                                    {GSMdata?.modem_status != 0 ? <a class="dropdown-item text-danger" onClick={() => (oninactive())} >InActive</a> : ''}
                                    {GSMdata?.modem_status != 2 &&GSMdata?.modem_status != -1? <a class="dropdown-item text-info" onClick={() => (onUnderMaintenance())} >Under Maintenance</a> : ''}
                                    {GSMdata?.modem_status != -1&&GSMdata?.modem_status != -1 ? <a class="dropdown-item !tw-text-[#f59e0b]" onClick={() => (onPenddingConfguration())} >Pending Configuration</a> : ''}
                                    
                                 </div>
                              </div>:''}
                              {rolesGSMedit==='true'||userInfo?.role=='ROLE1650614500708'?GSMdata?.modem_status == 1||GSMdata?.modem_status == 0?<div class="dropdown ml-2">
                                 <button class={`btn ${GSMdata?.modem_sub_status == 1 ? 'btn-success' : GSMdata?.modem_sub_status == 0 ? 'btn-danger' : GSMdata?.modem_sub_status == 2 ? 'btn-warning' : GSMdata?.modem_sub_status == 3 ? 'btn-danger' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {GSMdata?.modem_sub_status == 1 ? 'Live' : GSMdata?.modem_sub_status == 0 ? 'InActive' : GSMdata?.modem_sub_status == 2 ? 'Temporary Disconnection' : GSMdata?.modem_sub_status == 3 ? 'Permanent Disconnection' : 'Pending Configration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {GSMdata?.modem_status == 1&&GSMdata?.modem_sub_status != 1 ? <a class="dropdown-item text-success" onClick={() => (onOnline())} >Live</a> : ''}
                                    {GSMdata?.modem_status == 1&&GSMdata?.modem_sub_status != 2 ? <a class="dropdown-item text-warning" onClick={() => (onTemporaryDisconnection())} >Temporary Disconnection</a> : ''}
                                    {GSMdata?.modem_status == 0&&GSMdata?.modem_sub_status != 3 ? <a class="dropdown-item text-danger"  >Permanent Disconnection</a> : ''}
                                 </div>
                              </div>:'':''}
                              <div className='btn btn-primary ml-2' onClick={()=>{onGetConsumerStatusHistoryByConsumerId()}}><i className='fas fa-history'/></div>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitGsmDate}>
                              {!disable ? <fieldset class="customLegend">
                                 <legend>System Fields</legend>
                                 <div class="form-row  rounded p-3" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                    <div class="form-group col-md-3">
                                       <label for="inputPassword4">Created Time :</label>
                                       <input class="form-control" id="multiplier" name="multiplier" type="text" disabled="" placeholder="Created At" value={moment.utc(GSMdata?.CREATED_AT).format('DD-MM-YYYY hh:mm:ss A')} />
                                       <span class="font-10">Date Format DD-MM-YYYY hh:mm:ss</span>
                                    </div>
                                 </div>
                              </fieldset> : ''}
                              <fieldset class="customLegend">
												<legend>Group Details</legend>
												<div
													class="form-row p-3"
													style={{
														marginLeft: "10px",
														borderColor: "#e3e6fc !important",
														position: "relative",
														marginTop: "5px",
													}}
												>
													<div class="col-lg-4">
														<div class="form-group">
															<label for="inputPassword4">
																Account<span class="text-danger">*</span>
															</label>
															<select
																class="form-control form-select accountoga ga_calueacc"
																name="accountid"
																required=""
																id="accountid"
																onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
																value={Profile?.account}
                                                disabled={!disable}
															>
																<option value="">Select Account</option>
																{AccounData &&
																	AccounData.map((item) =>
																		permision?.ROLES_PERMISSIONS_CATEGORY0?.CATEGORY0_view?.filter(
																			(items) => items === item.UNIQUE_ID
																		).length > 0 ||userInfo?.role==='ROLE1650614500708'? (
																			<option value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
																		) : (
																			""
																		)
																	)}
															</select>
														</div>
													</div>
													{Profile.account !== "" && (
														<div class="col-lg-4 gagrp">
															<div class="form-group">
																<label for="inputPassword4">{NameObj?.CATEGORY1||'CATEGORY1'}</label>
																<select
																	class="form-control form-select gatocircle ga_calue"
																	name="address_location_d"
																	id="address_location_d"
																	onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
																	value={Profile?.ga}
                                                   disabled={!disable}
																>
																	<option value="">Select GA</option>
																	{GaData &&
																		GaData.map((item) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY1?.CATEGORY1_view?.filter(
																				(items) => items === item.CATEGORY1_UNIQUE_ID
																			).length > 0||userInfo?.role==='ROLE1650614500708' ? (
																				<option value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
													)}
													{Profile.ga !== "" && Profile.account !== "" && (
														<div class="col-lg-4 circlegrp">
															<div class="form-group">
																<label for="inputPassword4">{NameObj?.CATEGORY2||'CATEGORY2'}</label>
																<select
																	class="form-control circletodivision form-select"
																	name="circle_id"
																	onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
																	value={Profile?.circle}
                                                   disabled={!disable}
																>
																	<option value="">Select Circle</option>
																	{CircleData &&
																		CircleData.map((item) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY2?.CATEGORY2_view?.filter(
																				(items) => items === item.CATEGORY2_UNIQUE_ID
																			).length > 0 ||userInfo?.role==='ROLE1650614500708'? (
																				<option value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
													)}
													{Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
														<div class="col-lg-4 divisiongrp">
															<div class="form-group">
																<label for="inputPassword4">{NameObj?.CATEGORY3||'CATEGORY3'}</label>
																<select
																	class="form-control divisiontosubdivision form-select"
																	name="division_id"
																	id="address_location_d"
																	onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
																	value={Profile?.division}
                                                   disabled={!disable}
																>
																	<option value="">Select Division</option>
																	{DivisionData &&
																		DivisionData.map((item) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY3?.CATEGORY3_view?.filter(
																				(items) => items === item.CATEGORY3_UNIQUE_ID
																			).length > 0||userInfo?.role==='ROLE1650614500708' ? (
																				<option value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
													)}
													{Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
														<div class="col-lg-4 subdivisiongrp">
															<div class="form-group">
																<label for="inputPassword4">{NameObj?.CATEGORY4||'CATEGORY4'}</label>
																<select
																	class="form-control subdivisiontoarea form-select"
																	name="sub_division_id"
																	id="address_location_d"
																	onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
																	value={Profile?.subdivision}
                                                   disabled={!disable}
																>
																	<option value="">Select Sub Division</option>
																	{SubdevisionData &&
																		SubdevisionData.map((item) =>
																			permision?.ROLES_PERMISSIONS_CATEGORY4?.CATEGORY4_view?.filter(
																				(items) => items === item.CATEGORY4_UNIQUE_ID
																			).length > 0||userInfo?.role==='ROLE1650614500708' ? (
																				<option value={item.CATEGORY4_UNIQUE_ID}>
																					{item.CATEGORY4_NAME}
																				</option>
																			) : (
																				""
																			)
																		)}
																</select>
															</div>
														</div>
													)}
													{Profile.subdivision !== "" &&
														Profile.division !== "" &&
														Profile.circle !== "" &&
														Profile.ga !== "" &&
														Profile.account !== "" && (
															<div class="col-lg-4 areagrp">
																<div class="form-group">
																	<label for="inputPassword4">{NameObj?.CATEGORY5||'CATEGORY5'}</label>
																	<select
																		class="form-control form-select lastarea cust_ara"
																		name="area_id"
																		id="area_id"
																		onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
																		value={Profile?.area}
                                                      disabled={!disable}
																	>
																		<option value="">Select Area</option>
																		{AreaData &&
																			AreaData.map((item) =>
																				permision?.ROLES_PERMISSIONS_CATEGORY5?.CATEGORY5_view?.filter(
																					(items) => items === item.UNIQUE_ID
																				).length > 0 ||userInfo?.role==='ROLE1650614500708'? (
																					<option value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
																				) : (
																					""
																				)
																			)}
																	</select>
																</div>
															</div>
														)}
												</div>
											</fieldset>
                              {/* <fieldset class="customLegend">
                                <div> <legend>Distributor Customer Details</legend>
                                 </div>
                                 <div class="form-row p-3" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                    <div class="form-group col-md-3">
                                       <label for="cust_lname">Customer ID</label>
                                       <input class="form-control" id="cust_lname" name="distriuter_customer_id" type="text" placeholder="Distributor Customer ID" onChange={(e) => (setConsumer({ ...Consumer, cunsumerId: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={Consumer.cunsumerId} disabled={!disable} />
                                      
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label for="installation_number">Installation Number</label>
                                       <input class="form-control" id="installationo" name="installation_number" type="text" placeholder="Installation Number" onChange={(e) => (setConsumer({ ...Consumer, Installation: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={Consumer.Installation} disabled={!disable} />
                                       
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label for="business_partner_number">Business Partner Number</label>
                                       <input class="form-control" id="business_partner_number" name="business_partner_number" type="text" placeholder="Business Partner Number" onChange={(e) => (setConsumer({ ...Consumer, BPN: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={Consumer.BPN} disabled={!disable} />
                                       <span class="text-danger" id="business_partner_number_error"></span>
                                    </div>
                                    <div class="form-group col-md-3">
														<label for="business_partner_numbers">Consumer Type</label>
														<select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															id="filter_course"
															required=""
															onChange={(e) => {setConsumerType({ ...ConsumerType, CONSUMER_TYPE: e.target.value})
															}}
                                             disabled={!disable}
															value={ConsumerType.CONSUMER_TYPE}
														>
															<option value="">Select Consumer Type </option>
															<option value="Residential">Residential</option>
															<option value="Industrial">Industrial</option>
															<option value="Commercial">Commercial</option>
														</select>
													</div>
                                 </div>
                              </fieldset> */}
                              <fieldset class="customLegend">
                                 <legend>Modem Details</legend>
                                <div style={{position:'absolute',right:'0',top:'9px'}}>{rolesGSMedit==='true'||userInfo?.role=='ROLE1650614500708'? !ModemFlag?<div class="btn btn-info text-white btnedit mr-2 tw-text-[12px] tw-rounded-[30px]" onClick={() => (setModemFlag(true))} style={{padding:'2px 13px 2px 13px'}}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>:<><div class="btn btn-danger text-white btnedit mr-2" onClick={() => (onUpdateInGSMModemDataAndModemTable())}>
                              Submit
                              </div><div class="btn btn-light text-dark btnedit mr-2" onClick={() => {setModemFlag(false)
                              props.onConsumerUpdateDate()}}>
                              cancel
                              </div></>:''}</div>
                                 <div class="form-row p-3" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 <div class="form-group col-md-3">
														<label for="inputPassword4">
															Modem Profile 
														</label>
														<select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															id="filter_course"
															required=""
															onChange={(e) => {if(e.target.value==''){}else{setConsumerType({ ...ConsumerType, ModemId: e.target.value })
                                             onGetMeterDetails(e.target.value)
															}
															onGetModemTypeWiseIMEI(e.target.value)
															}}
															value={ConsumerType.ModemId}
                                             disabled={!ModemFlag}
														>
															<option value="">Select Modem Profile </option>
															{ModemData &&
																ModemData.map((item) => (
																	<option value={item.MODEM_UNIQUE_ID}>{item.MODEM_PROFILE_NAME}</option>
																))}
														</select>
													</div>
													<div class="form-group col-md-3">
														<label for="inputPassword4">
															IMEI:
														</label>
														<Select value={ConsumerType?.IMEI?[{label:ConsumerType?.IMEI,value:ConsumerType?.IMEI}]:[]} placeholder='Select IMEI...' options={[...IMEIList.map((item,id) => ({label:item.IMEI,value:item.IMEI})),{label:'+ Add IMEI',value:'open'}]} onChange={(e) => {
															console.log(e);
															if(!e){
																setConsumerType({...ConsumerType,IMEI:'',MSRNO:'',PSNO:''})
															}else{
										onGetIMEIWiseModemData(e.value)
                                             }
									}} isDisabled={!ModemFlag} isClearable/>
														{/* <select
															class="form-control form-select modemtypename anothermodemname"
															name="cust_sal"
															required=""
															onChange={(e) => {
																console.log(e.target.value)
																if(e.target.value===''){
																	
																	setConsumerType({...ConsumerType,IMEI:''})
																
																}
																
																}}
															value={ConsumerType.IMEI}
														>
															<option value="">Select Modem IMEI </option>
															{IMEIList &&
																IMEIList.map((item) => (
																	<option value={item.IMEI}>{item.IMEI}</option>
																))}
																{ConsumerType.ModemId!=''?<option value="open" className="alert alert-secondary text-dark" role="alert" >+ Add IMEI</option>:''}
														</select> */}
														{/* <input
															class="form-control "
															id="deviceIMEI"
															name="deviceIMEI"
															required=""
															type="text"
															placeholder="IMEI"
															maxlength="16"
															onChange={(e) => setConsumerType({ ...ConsumerType, IMEI: e.target.value })}
															value={ConsumerType.IMEI}
															style={{ textTransform: "lowercase" }}
														/> */}
														
													</div>
                                    <div class="form-group col-md-3">
                                       <label for="inputPassword4">Modem Serial No:</label>
                                       <input class="form-control" id="consmrid" required="" name="consmrid" type="text" placeholder="Modem Serial No" onChange={(e) => (setConsumerType({ ...ConsumerType, MSRNO: e.target.value }))} value={ConsumerType.MSRNO} disabled={true} />
                                      
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label for="inputPassword4">Panel Serial No:</label>
                                       <input class="form-control" id="panelserialno" name="panelserialno" type="text" placeholder="Panel Serial No" onChange={(e) => (setConsumerType({ ...ConsumerType, PSNO: e.target.value }))} value={ConsumerType.PSNO} disabled={true} />
                                       
                                    </div>
                                    {Object.keys(ModemInputs).length ? <div class="form-row meter_data_row" style={{ marginLeft: "10px", position: "relative", borderColor: "rgb(227, 230, 252) !important" }}>
                                       <div class="font-10">
                                          <span>Modem Profile : <span id="modem_type">{ModemInputs?.MODEM_PROFILE_NAME}</span></span> &nbsp;|| &nbsp;
                                          <span>Modem Make : <span id="modem_make">{ModemInputs?.MODEM_MAKE_NAME}</span></span>&nbsp;&nbsp;||&nbsp;
                                          <span>Modem Type : <span id="modem_make">{ModemInputs?.MODEM_TYPE}</span></span>&nbsp;&nbsp;||&nbsp;
                                          <span>Modem Model Version : <span id="modem_model">{ModemInputs?.MODEM_MODEL_NAME}</span></span>&nbsp;&nbsp;||&nbsp;
                                          <span>Modem Firmware : <span id="modem_firmware">{ModemInputs?.MODEM_FIRMWARE_NAME}</span></span>&nbsp;&nbsp;||&nbsp;
                                          <span>Modem SKU : <span id="modem_sku">{ModemInputs?.SKU_NAME}</span></span> &nbsp;&nbsp;|| &nbsp;
                                          <span>Modem Hardware Version : <span id="modem_hardware_version">{ModemInputs?.NAME}</span></span>&nbsp;&nbsp;||&nbsp;
                                          <span>Script Code ID  : <span id="SCRIPTDECODE_ID_V">{scripcodeid}</span></span>
                                       </div>
                                    </div> : ''}

                                 </div></fieldset>
                              <div class="col-lg-12" style={{ marginTop: "10px" }}>
                                 <ul class="nav nav-pills border" id="myTab3" role="tablist">
                                    <li class="nav-item">
                                       <a class={`nav-link ${NameTab=="Address"?'active':''}`} id="profile-tab3" data-toggle="tab" href="#profile3" role="tab" aria-controls="profile" aria-selected={NameTab=="Address"?"true":'false'} onClick={()=>(setNameTab('Address'))}>Address</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class={`nav-link ${NameTab=="Contact Person"?'active':''}`} id="contact-tab3" data-toggle="tab" href="#contact3" role="tab" aria-controls="contact" aria-selected={NameTab=="Contact Person"?"true":'false'} onClick={()=>(setNameTab('Contact Person'))}>Contact Person</a>
                                    </li>
                                    <li class="nav-item">
                                       <a class={`nav-link ${NameTab=="Installation Details"?'active':''}`} id="contact-tab3" data-toggle="tab" href="#installationdetails" role="tab" aria-controls="contact" aria-selected={NameTab=="Installation Details"?"true":'false'} onClick={()=>(setNameTab('Installation Details'))}>Installation Details</a>
                                    </li>
                                    {/* <li class="nav-item">
                                       <a class="nav-link" id="contact-tab3" data-toggle="tab" href="#remarks" role="tab" aria-controls="contact" aria-selected="false">Remarks</a>
                                    </li> */}
                                    <li class="nav-item">
                                       <a class={`nav-link ${NameTab=="Attachments"?'active':''}`} id="contact-tab3" data-toggle="tab" href="#attechments" role="tab" aria-controls="contact" aria-selected={NameTab=="Attachments"?"true":'false'} onClick={()=>(setNameTab('Attachments'))}>Attachments</a>
                                    </li>
                                 </ul>
                                 <div class="tab-content" id="myTabContent2">
                                    <div class={`tab-pane fade  ${NameTab=='Address'?'active show':''}`} id="profile3" role="tabpanel" aria-labelledby="profile-tab3" style={{ marginTop: "5px" }}>
                                       <div class="row">
                                          <div class="col-lg-6">
                                             <div class="form-group">
                                             <div class="form-group">
																	<label for="address_compony_name">Consumer Name:</label>
																	<input
																		type="text"
																		class="form-control present_street_area"
																		name="address_compony_name"
																		id="address_compony_name"
																		placeholder="Consumer Name"
																		onChange={(e) =>
																			serAccountInput({ ...AccountInput, address_compony_name: e.target.value.replace(/[\/\\"`\t\n]/g,'') })
																		}
                                                      disabled={!disable}
																		value={AccountInput.address_compony_name}
																	/>
																	<span class="text-danger" id="imeirepeterror" style={{ fontSize: "10px" }}>
															* Special Characters like Quotation mark ("),Backslash (\),Slash (/),New line,Horizontal tab not allowed
														</span>
																</div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_building_no">Flat, House or Building:</label>
                                                <input type="text" class="form-control present_home_no" name="address_building_no" id="address_building_no" placeholder="Flat, House or Building" disabled={!disable} onChange={(e) => (serAccountInput({ ...AccountInput, address_building_no: e.target.value.replace(/[\\"`\t\n]/g,'') }))} value={AccountInput.address_building_no} />
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_street_area">Area, Colony, Street, Village:</label>
                                                <input type="text" class="form-control present_street_area" name="address_street_area" id="address_street_area" placeholder="Area, Colony, Street, Village" onChange={(e) => (serAccountInput({ ...AccountInput, address_street_area: e.target.value.replace(/[\\"`\t\n]/g,'') }))} value={AccountInput.address_street_area} disabled={!disable} />
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_landmark_colony">Landmark:</label>
                                                <input type="text" class="form-control present_landmark_col" name="address_landmark_colony" id="address_landmark_colony" placeholder="Landmark" onChange={(e) => (serAccountInput({ ...AccountInput, address_landmark_colony: e.target.value.replace(/[\\"`\t\n]/g,'') }))} value={AccountInput.address_landmark_colony} disabled={!disable} />
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_pin_code">Pin Code:</label>
                                                <input type="text" class="form-control" name="address_pin_code" id="address_pin_code" placeholder="Pin Code" onChange={(e) => (serAccountInput({ ...AccountInput, address_pin_code: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={AccountInput.address_pin_code} disabled={!disable} />
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                       <label for="inputPassword4">Country</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, address_country_id: item.target.value })
                                    getState(item.target.value)}} value={AccountInput?.address_country_id} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select Country</option>
                                          {options && options.map((item) => <option value={item.id}>{item.country}</option>)}
                                       </select>
                                             {/* <GeoLocation
                                                locationTitle="Country"
                                                isCountry
                                                onChange={(item) => (serAccountInput({ ...AccountInput, address_country_id: item }))}
                                                country={AccountInput?.address_country_id}
                                                disabled={!disable}
                                             /> */}
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">State</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, address_state_id: item.target.value })
                                    getCity(item.target.value)}} value={AccountInput?.address_state_id} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select State</option>
                                          {stateData && stateData.map((item) => <option value={item.id}>{item.state}</option>)}
                                       </select>
                                             {/* <GeoLocation
                                                locationTitle="State"
                                                onChange={(item) => (serAccountInput({ ...AccountInput, address_state_id: item }))}
                                                geoId={AccountInput.address_country_id}
                                                state={AccountInput?.address_state_id}
                                                isState
                                                disabled={!disable}
                                             /> */}
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">City</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => (serAccountInput({ ...AccountInput, address_city_id: item.target.value }))} value={AccountInput?.address_city_id} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select City</option>
                                          {Citydata && Citydata.map((item) => <option value={item.id}>{item.city}</option>)}
                                       </select>
                                       
                                          </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">TimeZone</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => (setConsumerType({ ...ConsumerType, TIMEZONE: item.target.value }))} value={ConsumerType?.TIMEZONE} required="" disabled={!disable} name="meter_type" >
                                          {TimeZonelist && TimeZonelist.map((item) => <option value={item.offset*60}>{item.text}</option>)}
                                       </select>
                                          </div>
                                          </div>
                                       </div>
                                       <div class="row">
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_lattitude">Latitude:</label>
                                                <input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Lattitude" value={AccountInput?.address_lattitude || ''} onChange={(e) => (serAccountInput({ ...AccountInput, address_lattitude: e.target.value.replace(/[^0-9.]/gi, '') }))} disabled={!disable} />
                                                <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <label for="address_longitude">Longitude:</label>
                                                <input type="text" class="form-control" name="address_longitude" id="loca_long" placeholder="Longitude" value={AccountInput?.address_longitude || ''} onChange={(e) => (serAccountInput({ ...AccountInput, address_longitude: e.target.value.replace(/[^0-9.]/gi, '') }))} disabled={!disable} />
                                                <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                             <div class="form-group">
                                                <a class={`btn btn-primary ${!disable ? 'disabled' : ''}`} data-toggle="tooltip" data-placement="bottom" style={{ marginTop: "40px" }} title="" onClick={() => (setIsModalVisible(true))} id="maplink" data-original-title="Map"><i class="fa fa-location-arrow"></i></a>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class={`tab-pane fade  ${NameTab=='Contact Person'?'active show':''}`} id="contact3" role="tabpanel" aria-labelledby="contact-tab3" style={{ marginTop: "5px" }}>
                                       <div class="row">
                                          <div class="col-12">
                                             <div class="row" id="dynamic_field">
                                                {contactDetails.length > 0 && contactDetails.map((item, id) => (
                                                   <div class="col-6" id="coll1">
                                                      <div class="card card-primary">
                                                         <div class="card-header"><h4>{id + 1}.</h4>
                                                            <div class="card-header-action">
                                                               <button type="button" class={`btn btn-danger text-white btn_removefdg ${!disable ? 'disabled' : ''}`} id="1" onClick={() => (onDelete(id))}><i class="fa fa-times" aria-hidden="true" ></i></button></div></div><div class="card-body">
                                                            <div class="form-group">
                                                               <div class="form-group !tw-my-1 row" >
                                                                  <label for="salutation_other1" class="col-sm-3 col-form-label">Salutation</label>
                                                                  <div class="col-sm-9"><select class="form-control form-select" name="salutation_other[]" id="salutation_other1" onChange={(value) => handleChangeTask({ target: { name: "salutation", value, id } })} value={contactDetails[id].salutation} disabled={!disable}><option value=" ">Select Salutation</option><option value="Mr.">Mr.</option><option value="Ms.">Ms.</option><option value="Mrs.">Mrs.</option></select></div></div><div class="form-group !tw-my-1 row" ><label for="firstcontectname1" class="col-sm-3 col-form-label">First Name</label><div class="col-sm-9"><input type="text" class="form-control" placeholder="First Name" id="firstcontectname1" name="firstcontectname[]" onChange={(value) => handleChangeTask({ target: { name: "First_Name", value, id } })} value={contactDetails[id].First_Name} disabled={!disable} /></div></div><div class="form-group !tw-my-1 row" ><label for="lastcontectname1" class="col-sm-3 col-form-label">Last Name</label><div class="col-sm-9"><input type="text" class="form-control" placeholder="Last Name" id="lastcontectname1" name="lastcontectname[]" onChange={(value) => handleChangeTask({ target: { name: "Last_Name", value, id } })} value={contactDetails[id].Last_Name} disabled={!disable} /></div></div><div class="form-group !tw-my-1 row" ><label for="emailcontectname1" class="col-sm-3 col-form-label">Email</label><div class="col-sm-9"><input type="text" class="form-control" placeholder="Email" id="emailcontectname1" name="emailcontectname[]" onChange={(value) => handleChangeTask({ target: { name: "Email", value, id } })} value={contactDetails[id].Email} disabled={!disable} /> </div></div><div class="form-group !tw-my-1 row" >

                                                                  <label for="othercontectnumber1" class="col-sm-3 col-form-label">Mobile Number</label>
                                                                  <div class="col-sm-3">
                                                                     <select
                                                                        class="form-select form-control"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => setContactconCode(e.target.value)}
                                                                        value={ContactconCode}
                                                                        disabled={!disable}
                                                                     >
                                                                        {ContryCodeData &&
                                                                           ContryCodeData.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}

                                                                     </select>
                                                                  </div>

                                                                  <div class="col-sm-6"><input type="text" class="form-control" placeholder="Mobile Number" id="othercontectnumber1" name="othercontectnumber[]" onChange={(value) => handleChangeTask({ target: { name: "Mobile", value, id } })} value={contactDetails[id].Mobile} disabled={!disable} /></div></div><div class="form-group !tw-my-1 row"  >

                                                                  <label for="otherworkcontectnumber1" class="col-sm-3 col-form-label">Work Number</label>
                                                                  <div class="col-sm-3">
                                                                     <select
                                                                        class="form-select form-control"
                                                                        aria-label="Default select example"
                                                                        onChange={(e) => setContactconCode(e.target.value)}
                                                                        value={ContactconCode}
                                                                        disabled={!disable}
                                                                     >
                                                                        {ContryCodeData &&
                                                                           ContryCodeData.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}

                                                                     </select>
                                                                  </div>
                                                                  <div class="col-sm-6"><input type="text" class="form-control" placeholder="Work Number" id="otherworkcontectnumber1" name="otherworkcontectnumber[]" onChange={(value) => handleChangeTask({ target: { name: "Work_no", value, id } })} value={contactDetails[id].Work_no} disabled={!disable} /></div></div><div class="form-group !tw-my-1 row" ><label for="desginationcontect1" class="col-sm-3 col-form-label">Designation</label><div class="col-sm-9">
                                                                     <input type="text" class="form-control" placeholder="Designation" id="desginationcontect1" name="desginationcontect[]" disabled={!disable} onChange={(value) => handleChangeTask({ target: { name: "Designation", value, id } })} value={contactDetails[id].Designation} /></div></div><div class="form-group !tw-my-1 row"  ><label for="thisusertype1" class="col-sm-3 col-form-label">User type</label><div class="col-sm-9"><select class="form-control form-select" name="thisusertype[]" id="thisusertype1" onChange={(value) => handleChangeTask({ target: { name: "UserType", value, id } })} value={contactDetails[id].UserType} disabled={!disable}><option value="">Select User Type</option><option value="primary">Primary</option></select></div></div></div></div></div>
                                                   </div>
                                                ))}

                                             </div>
                                          </div>
                                       </div>
                                       <div class="input-group">
                                          <button type="button" class={`btn btn-info ${!disable ? 'disabled' : ''}`} id="add" onClick={onAdd}>+Add New Contact</button>
                                       </div>
                                    </div>
                                    <div class={`tab-pane fade  ${NameTab=='Installation Details'?'active show':''}`} id="installationdetails" role="tabpanel" aria-labelledby="contact-tab3" style={{ marginTop: "5px" }}>
                                       <div class="row">
                                          <div class="col-12">
                                             <div class="row">
                                                <div class="col-6">
                                                   <div class="card card-primary">
                                                      <div class="card-header">
                                                         <h4></h4>
                                                      </div>
                                                      <div class="card-body">
                                                         <div class="form-group ">
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="firstcontectname0" class="col-sm-3 col-form-label">First Name</label>
                                                               <div class="col-sm-9">
                                                                  <input type="text" class="form-control" placeholder="First Name" id="installation_person_fname" name="installation_person_fname" onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_First_Name: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={installDetails.Installation_Person_First_Name} disabled={!disable} />
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="lastcontectname0" class="col-sm-3 col-form-label">Last Name</label>
                                                               <div class="col-sm-9">
                                                                  <input type="text" class="form-control" placeholder="Last Name" id="installation_person_lname" name="installation_person_lname" onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_Last_Name: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={installDetails.Installation_Person_Last_Name} disabled={!disable} />
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="emailcontectname0" class="col-sm-3 col-form-label">Email</label>
                                                               <div class="col-sm-9">
                                                                  <input class="form-control" type="text" placeholder="Email" id="installation_person_email" name="installation_person_email" onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_Email: e.target.value }))} value={installDetails.Installation_Person_Email} disabled={!disable} />
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="othercontectnumber0" class="col-sm-3 col-form-label">Mobile Number</label>
                                                               <div class="col-sm-9">
                                                                  <div class="form-row">
                                                                     <div class="col-sm-3">
                                                                        <select
                                                                           class="form-select form-control"
                                                                           aria-label="Default select example"
                                                                           onChange={(e) => setConCode(e.target.value)}
                                                                           value={conCode}
                                                                           disabled={!disable}
                                                                        >
                                                                           {ContryCodeData &&
                                                                              ContryCodeData.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}
                                                                        </select>
                                                                     </div>
                                                                     <div class="col-sm-9">
                                                                        <input class="form-control" type="text" placeholder="Mobile Number" name="installation_person_mobile_number" id="installation_person_mobile_number" onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_Mobile_Number: e.target.value }))} value={installDetails.Installation_Person_Mobile_Number} disabled={!disable} />
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label class="col-sm-3 col-form-label">Work Number</label>
                                                               <div class="col-sm-9">
                                                                  <div class="form-row">
                                                                     <div class="col-sm-3">
                                                                        <select
                                                                           class="form-select form-control"
                                                                           aria-label="Default select example"
                                                                           onChange={(e) => setConCode(e.target.value)}
                                                                           value={conCode}
                                                                           disabled={!disable}
                                                                        >
                                                                           {ContryCodeData &&
                                                                              ContryCodeData.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}

                                                                        </select>
                                                                     </div>
                                                                     <div class="col-sm-9">
                                                                        <input class="form-control" type="text" placeholder="Work Number" name="installation_person_work_number" id="installation_person_work_number" onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_Work_Number: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={installDetails.Installation_Person_Work_Number} disabled={!disable} />
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="position" class="col-sm-3 col-form-label">Position</label>
                                                               <div class="col-sm-9">
                                                                  <input class="form-control" type="text" placeholder="Position" name="Installation_Person_Position" id="Installation_Person_Position"
                                                                     onChange={(e) => (setinstallDetails({ ...installDetails, Installation_Person_Position: e.target.value.replace(/[\\"`\t\n]/g,"") }))} value={installDetails.Installation_Person_Position} disabled={!disable} />
                                                               </div>
                                                            </div>
                                                            <div class="form-group !tw-my-1 row" >
                                                               <label for="desginationcontect" class="col-sm-3 col-form-label">Date And Time:</label>
                                                               <div class="col-sm-9">
                                                                  <Space direction="vertical" style={{ gap: "0px" }}>
                                                                  <DatePicker
  format="DD-MM-YYYY HH:mm:ss"
  showTime
  defaultValue={installDetails.Installation_Date_And_Time ? moment(installDetails.Installation_Date_And_Time,'DD-MM-YYYY HH:mm:ss') : null}
  onChange={(e) => {
    setinstallDetails({
      ...installDetails,
      Installation_Date_And_Time: e ? moment(e.$d).format('DD-MM-YYYY HH:mm:ss') : '',
    });
    console.log(e, e ? moment(e.$d).format('DD-MM-YYYY HH:mm:ss') : '');
  }}
  disabled={!disable}
/>
                                                                     {/* <span>{installDetails.Installation_Date_And_Time}</span> */}
                                                                  </Space>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class={`tab-pane fade  ${NameTab=='Remarks'?'active show':''}`} id="remarks" role="tabpanel" aria-labelledby="contact-tab3" style={{ marginTop: "5px" }}>
                                       <div class="col-lg-6">
                                          <div class="form-group">
                                             <label>Remarks:</label>
                                             <textarea class="form-control" name="cust_remarks" onChange={(e) => (setRemark(e.target.value))} value={remark} disabled={!disable}></textarea>
                                          </div>
                                       </div>
                                    </div>
                                    <div
                                       class={`tab-pane fade  ${NameTab=='Attachments'?'active show':''}`}
                                       id="attechments"
                                       role="tabpanel"
                                       aria-labelledby="contact-tab4"
                                       style={{ marginTop: "5px" }}
                                    >
                                       <p style={{ marginLeft: "10px" }} class="text-danger font-10">
                                          Note : * Max file Size 50MB
                                       </p>
                                       <div class="row">
                                          <div class="col-12">
                                             <div class="row" id="dynamic_attech">
                                                {Attachment &&
                                                   Attachment.map((item, id) => (
                                                      <>
                                                         <div class="col-6" id="colls1">
                                                            <div class="card card-primary">
                                                               <div class="card-header">
                                                                  <h4>{id + 1}</h4>
                                                                  {!disable ? <div class="card-header-action ">
                                                                     <a class="btn btn-warning text-white mr-2" onClick={() => { onDownloadFiles(item.file) }}><i class="fa fa-download" aria-hidden="true"></i></a>
                                                                     <a type="button" onClick={(e) => {
                                                                       onViewAttachments(e,item?.file)
                                                                     }} id="viewattech7" class="btn btn-info"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                                                  </div> :
                                                                     <div class="card-header-action">
                                                                        {item.file !== '' && typeof item.file === 'string' ? <><a class="btn btn-warning text-white mr-2" onClick={() => { onDownloadFiles(item.file) }}><i class="fa fa-download" aria-hidden="true"></i></a>
                                                                           <a onClick={(e) => {
                                                                             onViewAttachments(e,item?.file)
                                                                           }} class="btn btn-info mr-2 text-white"><i class="fa fa-eye" aria-hidden="true"></i></a></> : ''}
                                                                        {disable && <><a
                                                                           class="btn btn-danger text-white"
                                                                           id="1"
                                                                           onClick={() => (onDeleteAttachment(id,item?.file))}
                                                                        >
                                                                           <i class='fa fa-xmark' aria-hidden="true"></i>
                                                                        </a></>}
                                                                     </div>}
                                                               </div>
                                                               <div class="card-body">
                                                                  <div class="form-group">
                                                                     <div
                                                                        class="form-group row"
                                                                        style={{ marginTop: "-20px" }}
                                                                     >
                                                                        <label
                                                                           for="attechmenttitle1"
                                                                           class="col-sm-3 col-form-label"
                                                                        >
                                                                           Title
                                                                        </label>
                                                                        <div class="col-sm-9">
                                                                           <input
                                                                              type="text"
                                                                              class="form-control"
                                                                              placeholder="Title"
                                                                              disabled={!disable}
                                                                              onChange={(value) =>
                                                                                 handleChangeAttachment({
                                                                                    target: {
                                                                                       name: "title",
                                                                                       value,
                                                                                       id,
                                                                                    },
                                                                                 })
                                                                              }
                                                                              value={Attachment[id].title}
                                                                           />
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="form-group row"
                                                                        style={{ marginTop: "-20px" }}
                                                                     >
                                                                        <label
                                                                           for="lastcontectname1"
                                                                           class="col-sm-3 col-form-label"
                                                                        >
                                                                           Description
                                                                        </label>
                                                                        <div class="col-sm-9">
                                                                           <textarea
                                                                              class="form-control"
                                                                              id="attechmentdescr1"
                                                                              onChange={(value) =>
                                                                                 handleChangeAttachment({
                                                                                    target: {
                                                                                       name: "description",
                                                                                       value,
                                                                                       id,
                                                                                    },
                                                                                 })
                                                                              }
                                                                              disabled={!disable}
                                                                              value={Attachment[id].description}
                                                                           ></textarea>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        class="form-group row"
                                                                        style={{ marginTop: "-20px" }}
                                                                     >
                                                                        <label
                                                                           for="lastcontectname1"
                                                                           class="col-sm-3 col-form-label"
                                                                        >
                                                                           Attachment
                                                                        </label>
                                                                        <div class="col-sm-9">
                                                                        <div
                                                                           class="d-flex"
                                                                           style={{ padding:'2px' }}
                                                                           
                                                                        >
                                                                           <input
                                                                              type="file"
                                                                              class="form-control"
                                                                              id={"file-uploads"+id}
                                                                              accept="application/msword,image/gif,image/jpeg,application/pdf,image/png,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,.doc,.gif,.jpeg,.jpg,.pdf,.png,.xls,.xlsx,.zip"
                                                                              onChange={(value) =>
                                                                                 handleChangeAttachment({
                                                                                    target: {
                                                                                       name: "file",
                                                                                       value,
                                                                                       id,
                                                                                    },
                                                                                 })
                                                                              }
                                                                              hidden
                                                                              disabled={!disable}
                                                                           />
                                                                           <div className='btn btn-secondary text-dark mr-1' style={{ width:"120px" }} onClick= {()=>{document.getElementById('file-uploads'+id).click()}}>Upload File</div>
                                                                           <input type={'text'} class="form-control" value={item.file !== '' && typeof item.file === 'string' ?item.file:item?.file?.name?item?.file?.name:'No Choose File'}></input>
                                                                           </div>
                                                                           <span>{item?.file_size?`Size: ${item?.file_size||''}`:''}</span>
                                                                        </div>
                                                                        
                                                                     </div>
                                                                     <span>Created At: {item?.create_at!==''?moment(item?.create_at).format('DD-MM-YYYY hh:mm:ss A'):''}</span><br/>
                                                                        <span>Update At: {item?.update_at!==''?moment(item?.update_at).format('DD-MM-YYYY hh:mm:ss A'):''}</span><br/>
                                                                     {item.file!==''&& typeof item.file!=='string'?  <div className={`btn btn-primary ${loadUpload==true?'disabled btn-progress':''}`} onClick={()=>onUploadFile(id)}>Upload</div>:''}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </>
                                                   ))}
                                             </div>
                                          </div>
                                       </div>
                                   {Attachment.filter((item)=>(typeof item.file!=='string')).length>0?'': <div class="col-md-12 p-4">
                                          <button
                                             type="button"
                                             name="add"
                                             id="addatt"
                                             class={`btn btn-info ${!disable ? 'disabled' : ''}`}
                                             onClick={onAddAttachment}
                                          >
                                             +Add Attachment
                                          </button>
                                       </div>}
                                    </div>
                                 </div>
                              </div>
                              {disable ? <> <p style={{ marginLeft: "25px" }} class="text-danger font-10">Note : * Indicates Mandatory Fields</p>
                                 <div class="text-left">
                                    <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" />

                                    <a class="btn btn-light text-dark" onClick={onHandalCancel}>Cancel</a>
                                 </div></> : ''}
                           </form>
                        </div></>}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}

