import chroma from 'chroma-js';
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

const TabButton = ({ 
    label, 
    isActive, 
    onClick, 
    permission, 
    btnColorIndex,
    btnColor,
  }) => {
    const hasPermission = permission || userInfo?.ROLE === 'ROLE1661856938676';

    const btnColors  = btnColor ?? ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
  
    if (!hasPermission) return null;
  
    return (
      <li>
        <button
          style={{
            backgroundColor: isActive ? chroma(btnColors[btnColorIndex]).alpha(0.4).css() : '',
            color: isActive ? chroma(btnColors[btnColorIndex]).darken(1.5).css() : btnColors[btnColorIndex],
            borderBottom: `1px solid ${btnColors[btnColorIndex]}`,
            boxShadow: isActive ? `0 2px 8px -3px ${btnColors[btnColorIndex]}` : '',
          }}
          className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${isActive ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
          onClick={onClick}
        >
          {label}
        </button>
      </li>
    );
  };

export default TabButton;