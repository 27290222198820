
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../../Common/Pagination'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { BootstrapTooltip } from '../../Common/common';
import exports from '../../assets/img/export.png'
import signal1 from '../../assets/img/onesignal.png'
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import axios from 'axios';
import { GetEUIWiseModemData, GetGetwayData, GetLoraModemTypeWiseIMEI, InsertSlaveData, LoraConsumerSerchData, LorawanConsumerFilterData, UpdateLorawanData, getAllLoraMeter, getAllLoraModem, getCountUseToConsumerInEUI, getGSMData, getLoraModemByID, getSlavesData, getRolesById, getTags, getAllMeter, GetReportType, Generate_Report_trigger, SlaveConsumerFilterData, getSlaveGroups } from '../../Common/Utils'
import moment from 'moment';
import { DatePicker, Modal, TimePicker } from 'antd';
import { Users } from 'react-feather';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Lora_deshboard from './Slave_deshboard';
import MultiInput from '../../Common/MultiInput/MultiInput';
import classNames from 'classnames'
import { socket } from "../../Common/common";
import clockPending from "../../assets/img/icons8-clock.gif";
import Reports from '../SlaveReport/Reports';
import dayjs from 'dayjs';

window.Swal = swal;

$.fn.DataTable = dt;

const FilterDisplay = ({ filter, onClearFilter }) => {
	console.log('filter', filter)
	const [showAll, setShowAll] = useState(false);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const maxValues = 10;

	if (Object.entries(filter).length === 0) { return null }
	const filterEntries = Object.entries(filter);

	const totalValues = filterEntries.reduce((count, [, value]) => {
		if (Array.isArray(value)) {
			return count + value.length;
		} else {
			return count + 1;
		}
	}, 0);

	// const viewMoreButton = totalValues > maxValues ? (
	// 	<button
	// 		onClick={() => setShowAll(!showAll)}
	// 		className='tw-bg-blue-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-blue-500 hover:tw-bg-blue-200 tw-transition-all tw-mr-2'
	// 	>
	// 		{showAll ? 'View Less' : 'View More'}
	// 	</button>
	// ) : null;

	let valuesDisplayed = 0;
	let keysDisplayed = 0;

	const remainingValues = totalValues - maxValues;
	const viewMoreButton = totalValues > maxValues && !showAll ? (
		<button
			onClick={() => setShowAll(true)}
			className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all tw-mr-2'>
			+{remainingValues}
		</button>
	) : null;


	const filterValues = filterEntries.map(([key, value]) => {
		if (!showAll && valuesDisplayed >= maxValues) {
			console.log('returning null')
			return null;
		}

		let valueOutput;
		if (key === 'RSSI' || key === 'BAT_PER') {
			if (value?.LessEqual === 'True') {
				valueOutput = (
					<> LessAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1">{value?.Value}</span> </>
				);
			} else if (value?.GreaterEqual === 'True') {
				valueOutput = (
					<> GreaterAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1">{value?.Value}</span> </>
				);
			} else {
				valueOutput = (
					<> between{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border tw-whitespace-nowrap !tw-border-indigo-500">{value?.From}</span>{' '} and{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.To}</span> </>
				);
			}
			valuesDisplayed++;
		} else if (key === 'Last_Seen') {
			valueOutput = (
				<> {value?.LessEqual === 'True' ? 'less than' : 'more than'}{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Value} {value?.Last_Seen}</span>{' '} </>
			);
			valuesDisplayed++;
		} else if (key === 'Installation_Date' || key === 'Dataretrival_Date') {
			if (value?.LessEqual === 'True') {
				valueOutput = (
					<> LessAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span> </>
				);
			} else if (value?.GreaterEqual === 'True') {
				valueOutput = (
					<> GreaterAndEqualTo{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span> </>
				);
			} else {
				valueOutput = (
					<> between{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.Start_Date}</span>{' '} and{' '} <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap">{value?.End_Date}</span> </>
				);
			}
			valuesDisplayed++;
		} else if (Array.isArray(value)) {
			const displayedValues = showAll ? value : value.slice(0, maxValues - valuesDisplayed);
			valueOutput = displayedValues.map((v, i) => (
				<span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {v} </span>
			));
			valuesDisplayed += displayedValues.length;
		} else if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
			valueOutput = (
				<span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {JSON.stringify(value)} </span>
			);
			valuesDisplayed++;
		} else {
			valueOutput = (
				<span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500 tw-whitespace-nowrap"> {value} </span>
			);
			valuesDisplayed++;
		}

		return (
			<>
				<strong className="mr-1">{key}:</strong>
				{valueOutput}
			</>
		);
	});
	return (
		<div className={classNames("tw-mb-4 tw-px-1 tw-flex tw-items-center tw-gap-2")}>
			<h3 className="tw-my-0 tw-text-sm tw-font-bold tw-bg-[#f1f1f1] tw-py-1 tw-px-2 tw-rounded-lg">Filters:</h3>

			{showAll && (
				<div className="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-items-center">
					<div className="tw-absolute tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-300 tw-z-50 tw-max-w-[90%]">
						<div className="tw-flex tw-flex-wrap tw-gap-1 tw-items-center">

							{filterValues}
							<button onClick={() => setShowAll(false)} className='tw-bg-orange-100 tw-whitespace-nowrap tw-rounded-md tw-px-2 tw-py-1 border !tw-border-orange-500 hover:tw-bg-orange-200 tw-transition-all'> View Less </button>
						</div>
					</div>
				</div>
			)
			}
			<div className="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-items-center tw-max-w-[90%]">
				<>
					{filterValues.slice(0, maxValues)}
					{viewMoreButton}
				</>

				<div className=''>
					<button onClick={onClearFilter} className='tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'> Clear Filter </button>
				</div>
			</div>
		</div>
	);
}
export default function Slave({ GSMdata, component_type }) {
	const [PageSize, setPageSize] = useState(50)
	const [numberofPage, setnumberofPage] = useState(1)
	const [currentPage, setCurrentPage] = useState(1);
	const [getwayList, setGetwayList] = useState([])
	const [GetwayListSerch, setGetwayListSerch] = useState([])
	const [getwayDiscription, setgetwayDiscription] = useState('')
	const [ismodel, setModel] = useState(false)
	const [loader, setLoader] = useState(false)
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [InputText, setInputText] = useState('')
	const [ConsumerType, setConsumerType] = useState({ MeterId: "", ModemId: "", IMEI: "", MSRNO: "", PSNO: "", MF: "1", METERSRNO: '', CONSUMER_TYPE: '' });
	const [ModemInputs, setModemInputs] = useState({});
	const [ModemData, setModemData] = useState([])
	const [FilterModemData, setFilterModemData] = useState([])
	const [MeterData, setMeterData] = useState([])
	const [isModalModem, setisModalModem] = useState(false)
	const [IMEIList, setIMEIList] = useState([])
	const [pole_Details, setpole_Details] = useState({ lattitude: '', longitude: '', pole_id: '', feeder_name: '', group_id: [] })
	const [LoraConsumerId, setLoraConsumerId] = useState('')
	const [BtnFlag, setBtnFlag] = useState(false)
	const [OpenConsumerDash, setOpenConsumerDash] = useState(false)
	const [ConsumerDashDetails, setConsumerDashDetails] = useState({})
	const [searchTerms, setSearchTerms] = useState([]);
	const [serachLoader, setserachLoader] = useState(false)
	const history = useNavigate()
	const [filterObj, setFilterObj] = useState({})
	const [showfilterObj, setShowFilterObj] = useState({})
	const [ismodelFilter, setismodelFilter] = useState(false)
	const [tagsList, settagsList] = useState([])
	const [FilterLoader, setFilterLoader] = useState(false)
	const [CreateTableStatus, setCreateTableStatus] = useState({});
	const [tableDetails, settableDetails] = useState({});
	const [ModemResult, setModemResult] = useState(false);
	const [ReportType, setReportTypes] = useState([])
	const [report, setReport] = useState({ value: '', name: '', start_date: '', end_date: '', intervalrType: '' })
	const [reportType, setReportType] = useState('')
	const [reportLoader, setreportLoader] = useState(false)
	const [isreportHistory, setisreportHistory] = useState(false)
	const [GroupDetails, setGroupDetails] = useState([])
	const [searchParams] = useSearchParams();

	const [DRState, setDRState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [consumer, setConsumer] = useState({
		checktext1: false,
		checktext2: false,
		checktext3: false,
		checktext4: false,
		checktext5: false,
		checktext6: false,
		checktext7: false,
		checktextCS: false,
		checktextPI: false,
		checktextMOP: false,
		checktextTA: false,
		checktextInTS: false,
		checktextLSs: false,
		Consumer_Type: false,
		checktextDRTS: false,
		Consumer_Priority: false,
		checktextSNR: false,
		checktextSF: false
	})

	const [modem, setModem] = useState({
		checktextRSSI: false,
		checktextLS: false,
		checktextBP: false,

	})

	const [modemStart, setModemState] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [betteryPr, setbetteryPr] = useState({
		Between: false,
		lessThan: false,
		greaterThan: false
	})

	const [last, setLastSeen] = useState({
		lessThan: false,
		greaterThan: false
	})
	const dataWise = [
		{ value: "Between", label: 'Is between' },
		{ value: "LessEqual", label: 'Is Less than Equel to' },
		{ value: "GreaterEqual", label: "Is Greater than Equel to" }
	]
	const lastSeenWise = [
		{ value: "LessEqual", label: 'Less than' },
		{ value: "GreaterEqual", label: 'Greater than' }
	]
	const lastSeenWiseOption = [
		{ value: "Hours", label: 'Hours' },
		{ value: "Minutes", label: 'Minutes' }
	]
	const consumers = [
		{ value: 'all', label: 'All' },
		{ value: '1', label: 'Active' },
		{ value: '0', label: 'InActive' },
		{ value: '-1', label: 'Pending Configurations' },
		{ value: '2', label: 'Under Maintenance' },
		{ value: '3', label: 'Stand By' }

	]
	const animatedComponents = makeAnimated();
	const customStyles = {
		menu: provided => ({ ...provided, zIndex: 9999 }),
		multiValue: (provided, { data }) => {
			return {
				...provided,
				width: 'min-content',
				backgroundColor: "#6777ef",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
		}),


	}
	const onHandaleList = async (info) => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getSlavesData({ start: firstPageIndex, end: PageSize }, { consumer_unique_id: GSMdata?.unique_id, component_type: component_type, ...info, Roles: userInfo?.role })
		if (data.success == true) {
			setGetwayList(data.data.data)
			setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
			setLoader(false)

		} else {
			console.log('somethig want wrong')
			setLoader(false)

		}
	}
	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.role)
		if (data.success === true) {
			const obj = data.data.data[0]
			const Roles = { ...obj, roles_permission_category0: JSON.parse(obj?.roles_permission_category0 || '{}'), roles_permission_category5: JSON.parse(obj?.roles_permission_category5 || '{}'), roles_permission_category2: JSON.parse(obj?.roles_permission_category2 || '{}'), roles_permission_category3: JSON.parse(obj?.roles_permission_category3 || '{}'), roles_permission_category1: JSON.parse(obj?.roles_permission_category1 || '{}'), roles_permission_category4: JSON.parse(obj?.roles_permission_category4 || '{}'), roles_other_permission: JSON.parse(obj?.roles_other_permission || '{}') }
			onHandaleList({ CATEGORY1_view: Roles?.roles_permission_category1?.CATEGORY1_view, CATEGORY0_view: Roles?.roles_permission_category0?.CATEGORY0_view, CATEGORY2_view: Roles?.roles_permission_category2?.CATEGORY2_view, CATEGORY3_view: Roles?.roles_permission_category3?.CATEGORY3_view, CATEGORY4_view: Roles?.roles_permission_category4?.CATEGORY4_view, CATEGORY5_view: Roles?.roles_permission_category4?.CATEGORY5_view, Roles: userInfo?.role })
			setpermision(Roles)
		} else {
			console.log('something want wrong')
		}
	}

	const onHandaleSlaveGroup = async () => {
		const data = await getSlaveGroups()
		if (data.success === true) {
			setGroupDetails(data.data.data.map((item) => ({ value: item?.unique_id, label: item?.group_name })))
		} else {
			console.log('Something Went Wrong')
		}
	}

	useEffect(() => {
		if (component_type == 'Group') {
			onHandaleRoles()
		} else {
			onHandaleList()

		}
		onHandaleSlaveGroup()
		handaleModemSelect()
		onGetTags()
		setFilterObj({ ...filterObj, Status: '1' })
		setShowFilterObj({ ...showfilterObj, Status: 'Active' })
		socket.on("mqttslavecreatetable", (massges) => {
			console.log(massges)
			if (massges?.scriptdecode_id) {
				if (
					tableDetails?.scriptdecode_id == massges?.scriptdecode_id &&
					tableDetails?.msgid == massges?.msgid
				) {
					setCreateTableStatus(massges);
				}
			}
		});
		const slave_unique_id = searchParams.get('slave_id')
		if (slave_unique_id) {
			setOpenConsumerDash(true)
			setConsumerDashDetails({ unique_id: slave_unique_id })
		}
	}, [GSMdata]);
	const onGetTags = async () => {
		// const data = await getTags()
		// if (data.success === true) {
		// 	settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME })))
		// } else {
		// 	console.log('something want wrong');
		// }
	}
	const handleChangeS = (e) => {
		if (e.target.checked) {

			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: true })
				if (e.target.name == 'SNR' || e.target.name == 'SF') {
					setFilterObj({ ...filterObj, [e.target.name]: {} })
				} else {
					setFilterObj({ ...filterObj, [e.target.name]: '' })
				}

			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: true })
			}
			if (e.target.id == 'checktextLS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextRSSI') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextInTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextBP') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
			if (e.target.id == 'checktextDRTS') {
				setFilterObj({ ...filterObj, [e.target.name]: {} })
			}
		} else {

			if (consumer.hasOwnProperty(e.target.id)) {
				setConsumer({ ...consumer, [e.target.id]: false })
				delete filterObj[e.target.name]
				delete showfilterObj[e.target.name]
				if (e.target.id == 'checktextDRTS') {
					setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
				}
			}
			if (modem.hasOwnProperty(e.target.id)) {
				setModem({ ...modem, [e.target.id]: false })
				if (e.target.id == 'checktextRSSI') {
					delete filterObj[e.target.name]
					delete showfilterObj[e.target.name]
					setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextBP') {
					delete filterObj[e.target.name]
					delete showfilterObj[e.target.name]
					setbetteryPr({ ...betteryPr, greaterThan: false, lessThan: false, Between: false })
				}
				if (e.target.id == 'checktextLS') {
					console.log(e.target.id)
					delete filterObj[e.target.name]
					delete showfilterObj[e.target.name]
					setLastSeen({ ...last, greaterThan: false, lessThan: false })
				}
			}
		}
	}

	const handleSelectChange = (e) => {
		const stateUpdate = Object.keys(modemStart)
		setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, RSSI: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};

	const handleSNRSFChange = (e, name) => {
		const stateUpdate = Object.keys(modemStart)
		setModemState(stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, [name]: stateUpdate.reduce((obj, data) => ({ ...obj, [e.value]: "True" }), {}) })
	};


	const handleLastSeen = (e) => {
		const lastSeenUpdate = Object.keys(last)
		setLastSeen(lastSeenUpdate.reduce((obj, data) => ({ ...obj, [e.value]: true }), {}))
		setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, [e.value]: true } })

	};

	useEffect(() => {
		if (Object.keys(permision).length > 0) {
			onHandaleList()
		}
	}, [currentPage, PageSize])




	const handleCancel = () => {
		setModel(false);
	};

	const onHandaleViewGSM = (id) => {
		// history.push(`/view_existing_consumer_lorawan/${id}`)
		setOpenConsumerDash(true)
		setConsumerDashDetails(id)
	}

	const handaleModemSelect = async () => {

		const data7 = await getAllMeter(true);
		if (data7.success === true) {
			const option_meter = []
			data7?.data?.data?.map((item) => (option_meter.push({ value: item?.METER_UNIQUE_ID, label: item?.METER_PROFILE_NAME })))
			setMeterData(option_meter);
		}
	}

	const onHandaleAddGroup = async () => {
		console.log(GSMdata)
		setBtnFlag(true)
		if (ConsumerType?.MeterId && pole_Details?.pole_id != '') {
			var ErrorStr = ''
			const obj = {
				MSRNO: ConsumerType?.MSRNO,
				pole_id: pole_Details?.pole_id,
				lattitude: pole_Details?.lattitude,
				longitude: pole_Details?.longitude,
				consumer_unique_id: GSMdata?.unique_id,
				userId: userInfo?.unique_id,
				MeterId: ConsumerType?.MeterId,
				scriptdecode_id: GSMdata?.modem_type + '_' + ConsumerType?.MeterId,
				payload: {
					msgid: new Date().valueOf(),
					scriptdecode_id: GSMdata?.modem_type + '_' + ConsumerType?.MeterId,
					unique_table_name: GSMdata?.unique_table_name || {}
				},
				feeder_name: pole_Details?.feeder_name,
				group_id: pole_Details?.group_id
			}
			console.log(obj)
			const data = await InsertSlaveData(obj, ConsumerType?.IMEI)
			if (data.success == true) {
				toast.success('Successfull Add Slave')
				setBtnFlag(false)
				setisModalModem(false)
				// setModemResult(true)
				// settableDetails(obj?.payload)
				onHandaleList()
			} else if (data?.err?.response?.status === 403) {
				toast.error('Table Not Created')
				setBtnFlag(false)
			} else if (data?.err?.response?.status === 405) {
				toast.error('Table Key Not Found')
				setBtnFlag(false)
			} else if (data?.err?.response?.status === 501) {
				toast.error('Already Added Slave Address')
				setBtnFlag(false)
			} else {
				toast.error('Server Issue')
				setBtnFlag(false)
			}
		} else {
			toast.error('Select Slave Type and Input Slave Address')
			setBtnFlag(false)

		}
	}

	const onHandaleModem = async () => {
		// const data = await getLoraModemByID(ConsumerType.ModemId);
		// if (data.success === true) {
		// 	data.data.data.length > 0 ? setModemInputs(data.data.data[0]) : setModemInputs({});
		// } else {
		// 	console.log("something want wrong");
		// }
	};

	useEffect(() => {
		if (ConsumerType.ModemId != '') {
			onHandaleModem()
		} else {
			setModemInputs({});
		}
	}, [ConsumerType.ModemId])



	const onHandalSearchMulti = (newSearchTerms) => {
		setSearchTerms(newSearchTerms);

	};

	const onItemCleared = (clearedIndex) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onHandalSearchMulti(newSearchTerms);
	};
	const onClearAllItems = () => {
		// Reset the search results to the original data
		onHandalSearchMulti([]);
	};

	const onHandalSerch = async () => {
		setserachLoader(true)

		if (searchTerms.length == 0) {
			onHandaleList()
			setserachLoader(false)

		} else {
			// const data = await LoraConsumerSerchData({ text: searchTerms,Roles:userInfo?.role,filter_obj: filterObj,group_consumer_id:GSMdata?.unique_id })
			// if (data.success === true) {
			// 	setGetwayList(data.data.data)
			// setGetwayListSerch(data.data.data)
			// setnumberofPage(parseInt(data.data.totalCount))
			// 	// setGetwayList(data.data.data)
			// 	// setnumberofPage(parseInt(data.data.totalCount))
			// setserachLoader(false)

			// } else {
			// 	console.log('something want warong')
			setserachLoader(false)

			// }
		}
	}

	const onSubmiteFilter = async () => {
		if (Object.keys(filterObj).length > 0) {
			setFilterLoader(true)
			const firstPageIndex = (currentPage - 1) * PageSize;
			const data = await SlaveConsumerFilterData({ start: firstPageIndex, end: PageSize }, { ...filterObj, roles: userInfo?.role, group_consumer_id: GSMdata?.unique_id, component_type: component_type, CATEGORY1_view: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY0_view: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY2_view: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3_view: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4_view: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5_view: permision?.roles_permission_category4?.CATEGORY5_view, roles: userInfo?.role })
			if (data.success == true) {
				setGetwayList(data.data.data)
				setGetwayListSerch(data.data.data)
				setnumberofPage(parseInt(data.data.totalCount))
				setismodelFilter(false)
				setLoader(false)
				setFilterLoader(false)

			} else {
				setFilterLoader(false)
				setLoader(false)
				console.log('somethig want wrong')
			}
		} else {
			onHandaleList()
			setFilterLoader(false)
			setLoader(false)
		}

	}

	const onClearFilter = async () => {
		setFilterLoader(true)
		setFilterObj({ Status: '1' })
		setShowFilterObj({ Status: 'Active' })
		setDRState({ ...DRState, greaterThan: false, lessThan: false, Between: false })
		setModemState({ ...modemStart, greaterThan: false, lessThan: false, Between: false })
		setLastSeen({ ...last, greaterThan: false, lessThan: false })
		setConsumer({
			checktext1: false,
			checktext2: false,
			checktext3: false,
			checktext4: false,
			checktext5: false,
			checktext6: false,
			checktext7: false,
			checktextCS: false,
			checktextPI: false,
			checktextMOP: false,
			checktextTA: false,
			checktextInTS: false,
			checktextLSs: false,
			Consumer_Type: false,
			checktextDRTS: false,
			checktextSF: false,
			checktextSNR: false,

		})
		setModem({
			checktextRSSI: false,
			checktextLS: false,
			checktextBP: false,
		})

		const emptyObj = {};
		const firstPageIndex = (currentPage - 1) * PageSize;
		const data = await SlaveConsumerFilterData({ start: firstPageIndex, end: PageSize }, { Status: '1', roles: userInfo?.role, group_consumer_id: GSMdata?.unique_id, component_type: component_type })
		if (data.success == true) {
			setGetwayList(data.data.data)
			setGetwayListSerch(data.data.data)
			setnumberofPage(parseInt(data.data.totalCount))
			setismodelFilter(false)
			setLoader(false)
			setFilterLoader(false)
		} else {
			setFilterLoader(false)
			setLoader(false)
			console.log('somethig want wrong')
		}
	}

	const onSearchMultiFilterJson = (newSearchTerms, json_name) => {
		setFilterObj({ ...filterObj, [json_name]: newSearchTerms })

	};

	const onItemClearedFilterJson = (clearedIndex, json_name) => {
		// Create a new array without the cleared item
		const newSearchTerms = searchTerms.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onSearchMultiFilterJson(newSearchTerms, json_name);
	};
	const onClearAllItemsFilterJson = (arr, json_name) => {
		// Reset the search results to the original data
		onSearchMultiFilterJson([], json_name);

	};

	const onCloseMassg = () => {
		setModemResult(false)
		setisModalModem(false)
		setConsumerType({ MeterId: "", ModemId: "", IMEI: "", MSRNO: "", PSNO: "", MF: "1", METERSRNO: '', CONSUMER_TYPE: '' })
		setpole_Details({ lattitude: '', longitude: '', pole_id: '', feeder_name: '', group_id: [] })
		settableDetails({})
	}

	const onGetReportTypeGSM = async () => {
		const data = await GetReportType({ type: 'GSM', report_cons_type: 'slave' })
		if (data.success == true) {
			var arrayReport = []
			data?.data?.data?.map((item) => (arrayReport.push({ value: item?.unique_id, label: item?.front_type_name, description: item?.description })))
			setReportTypes(arrayReport)
		} else {

		}
	}
	const showModal = (data) => {
		setModel(true);
		setgetwayDiscription(data)
		onGetReportTypeGSM()
	};

	function getStartDateFromFormattedInput(input, isStart) {
		const [year, weekWithSuffix] = input.split('-');
		const week = parseInt(weekWithSuffix.replace(/\D/g, ''), 10); // Remove 'th' or other non-numeric characters

		if (isStart) {
			return moment().year(year).isoWeek(week).startOf('isoWeek').subtract(1, 'days').format('YYYY-MM-DD');
		} else {
			return moment().year(year).isoWeek(week).endOf('isoWeek').subtract(1, 'days').format('YYYY-MM-DD');
		}


	}

	const ongetGenretedAllGSMReport = async (e) => {
		e.preventDefault()
		var obj = { file_type: reportType, table_type: report?.table_type, report_type: report.value, report_name: report.name, start_date: report?.start_date, end_date: report?.end_date, user_id: userInfo.unique_id, user_name: userInfo.first_name + ' ' + userInfo.last_name, roles: userInfo?.role, consumer_id: GSMdata?.unique_id, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view }, filter_obj: JSON.stringify(filterObj), component_type, start_interval: report.start_interval }
		if (report.name !== '' && report.value !== '' && reportType !== '' && report.start_interval !== '') {
			console.log(permision)

			if (report.value === 'RTI3459856669' || report.value == 'RTI3459856670') {

				if (report?.intervalrType === '' && report.value === 'RTI3459856669') {
					return toast.warn('Please Select "Interval Type"')
				}

				var startDate = moment(report?.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
				var endDate = moment(report?.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')

				if (report?.intervalrType === 'weekly') {
					startDate = getStartDateFromFormattedInput(report?.start_date, true)
					endDate = getStartDateFromFormattedInput(report?.end_date, false)
				}

				if (report?.intervalrType === 'monthly') {
					startDate = report?.start_date ? moment(report.start_date, 'MM-YYYY').startOf('month').format('YYYY-MM-DD') : null;
					endDate = report?.end_date ? moment(report.end_date, 'MM-YYYY').endOf('month').format('YYYY-MM-DD') : null;
				}



				const x = new Date(moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'));
				const y = new Date(moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'));
				if (report?.start_date == '' || (report?.end_date == '')) {
					return toast.warn('Please Select "Start Date" And "End Date"')
				} else {
					if (x > y) {
						return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}

				obj = { ...obj, start_date: startDate, end_date: endDate, durationType: report?.intervalrType }
			}
			if (report.value === 'RTI3452366456') {
				const x = new Date(moment(report.start_date, 'MM-YYYY').format('YYYY-MM-01'));
				const y = new Date(moment(report.end_date, 'MM-YYYY').format('YYYY-MM-31'));
				if (report?.start_date == '' || (report?.end_date == '')) {
					return toast.warn('Please Select "Start Date" And "End Date"')
				} else {
					if (x > y) {
						return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}
			}
			if (report.value === 'RTI3459856623' || report.value === 'RTI5632154893') {
				const x = new Date(moment(report.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
				const y = new Date(moment(report.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
				if (report?.start_date == '' || (report?.end_date == '')) {
					return toast.warn('Please Select "Start Date" And "End Date"')
				} else {
					if (x > y) {
						return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}
			}
			setreportLoader(true)
			const data = await Generate_Report_trigger(obj)
			if (data.success === true) {
				setreportLoader(false)

				swal.fire('Download Proccess Start', 'Please Check After 15 to 20 minutes in Report Tab', 'success');
				handleCancel()
			} else {
				setreportLoader(false)

				toast.error('Something Wait Wrong')
			}
		} else {
			return toast.warn('Please Fill Mandatory Fields')

		}
	}
	const onHandalReport = (e) => {
		setReport({ ...report, value: e.target.value, name: ReportType.filter((item) => (item?.value == e.target.value))[0]?.label, start_date: '', end_date: '', intervalrType: ''  })
	}

	
	return (
		<>

			<Modal
				visible={ModemResult}
				footer=""
				maskClosable={false}
				onCancel={() => onCloseMassg()}
				width={500}
			>
				<h5 class="modal-title text-center" id="meter_data_mqtt_info_txt">
					Getting Response For Table Create
				</h5>
				{ConsumerType?.MeterId ? (
					<h5
						class="modal-title text-center mt-2 "
						id="meter_data_mqtt_info_txt"
					>
						{!CreateTableStatus?.result &&
							CreateTableStatus?.result !== "SUCCESS" ? (
							<img
								className="mr-2"
								src={clockPending}
								height="20px"
								width={"20px"}
							></img>
						) : CreateTableStatus?.result === "SUCCESS" ? (
							<i className="fas fa-check mr-2" style={{ color: "green" }}></i>
						) : (
							<i className="fas fa-times mr-2" style={{ color: "red" }}></i>
						)}{" "}
						Slave Tables{" "}
						{CreateTableStatus?.result &&
							CreateTableStatus?.result !== "SUCCESS"
							? `(${CreateTableStatus?.result})`
							: ""}
					</h5>
				) : (
					""
				)}
				{/* {ConsumerType?.MeterId?<h5 class="modal-title text-center mt-2 " id="meter_data_mqtt_info_txt">{!MeterResult?.RESULT&&MeterResult?.RESULT!=='SUCCESS'?<img className='mr-2' src={clockPending} height='20px' width={'20px'}></img>:MeterResult?.RESULT==='SUCCESS'?<i className='fas fa-check mr-2' style={{color:'green'}}></i>:<i className='fas fa-times mr-2' style={{color:'red'}}></i>} Slave Tables {MeterResult?.RESULT&&MeterResult?.RESULT!=='SUCCESS'?`(${MeterResult?.RESULT})`:''}</h5>:''} */}
				<div class=""></div>
				<div class="text-center" style={{ marginTop: "20px" }}>
					<input
						type="button"
						class="btn btn-danger "
						value="Cancel"
						id="meter_data_mqtt_cancel_button"
						name="meter_data_mqtt_cancel_button"
						onClick={() => onCloseMassg()}
					/>
				</div>
			</Modal>
			<Modal title='Add Slave' visible={isModalModem} footer='' onCancel={() => setisModalModem(false)} width={1000}>

				<fieldset class="customLegend">
					<legend>Slave Details</legend>
					<div
						class="form-row p-3"
						style={{
							marginLeft: "10px",
							borderColor: "#e3e6fc !important",
							position: "relative",
							marginTop: "5px",
						}}
					>
						<div class="form-group col-md-3">
							<label for="inputPassword4">
								Slave Profile
							</label>
							<select
								class="form-control form-select second_meter_type skjghudfjgnv"
								onChange={(e) => { setConsumerType({ ...ConsumerType, MeterId: e.target.value }) }}
								value={ConsumerType.MeterId}
								required=""
								name="meter_type"
							>
								<option value="">Select Slave Profile</option>
								{MeterData &&
									MeterData.map((item) => (
										<option value={item.value}>{item.label}</option>
									))}
							</select>
						</div>
						<div class="form-group col-md-3">
							<label for="inputPassword4">
								Slave Serial No:
							</label>
							<input
								class="form-control "
								id="consmrid"
								required=""
								name="consmrid"
								type="text"
								placeholder="Modem Serial No"
								onChange={(e) => setConsumerType({ ...ConsumerType, MSRNO: e.target.value })}
								value={ConsumerType.MSRNO}
							/>

						</div>
					</div>
				</fieldset>
				<fieldset class="customLegend">
					<legend>Slave Details</legend>
					<div
						class="form-row p-3"
						style={{
							marginLeft: "10px",
							borderColor: "#e3e6fc !important",
							position: "relative",
							marginTop: "5px",
						}}
					>
						<div class="form-group col-md-3">
							<label for="address_lattitude">Feeder Name:</label>
							<input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Feeder Name" value={pole_Details?.feeder_name || ''} onChange={(e) => (setpole_Details({ ...pole_Details, feeder_name: e.target.value }))} />
						</div>
						<div class="form-group col-md-3">
							<label for="address_lattitude">Slave Address:</label>
							<input type="number" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Slave Address" value={pole_Details?.pole_id || ''} onChange={(e) => (setpole_Details({ ...pole_Details, pole_id: e.target.value }))} />
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label for="address_lattitude">Latitude:</label>
								<input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Lattitude" value={pole_Details?.lattitude || ''} onChange={(e) => (setpole_Details({ ...pole_Details, lattitude: e.target.value }))} />
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label for="address_longitude">Longitude:</label>
								<input type="text" class="form-control" name="address_longitude" id="loca_long" placeholder="Longitude" value={pole_Details?.longitude || ''} onChange={(e) => (setpole_Details({ ...pole_Details, longitude: e.target.value }))} />
							</div>
						</div>
						<div class="col-lg-3">
							<div class="form-group">
								<label for="address_longitude">Group:</label>
								<Select placeholder='Select Group...' isMulti isClearable options={GroupDetails} onChange={(e) => {
									setpole_Details({ ...pole_Details, group_id: e.map((item) => (item?.value)) })
								}} style={{ width: '100px' }} value={GroupDetails?.filter((item) => (pole_Details?.group_id?.includes(item?.value)))} />
							</div>
						</div>
					</div>
				</fieldset>
				<p style={{ marginLeft: "25px" }} class="text-danger font-10">Note : * Indicates Mandatory Fields</p>
				<div class="text-left">
					<input type="submit" name="submit" class="btn btn-danger mr-4" value="Submit" id="submitvn" onClick={onHandaleAddGroup} />

					<Link class="btn btn-light text-dark" to="/All-GSM">Cancel</Link>
				</div>
			</Modal>
			<Modal title="GSM Consumers Filter" visible={ismodelFilter} footer='' onCancel={() => setismodelFilter(false)} width={1500}>

				<fieldset className="customLegend ">
					<legend >Consumer Info Wise Filter</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextPI" name="slave_address" className="custom-control-input" value="modemserial" checked={consumer.checktextPI} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextPI">Slave Address</label>
								</div>
								{consumer.checktextPI && <MultiInput
									placeholder="search and enter..."
									onChange={(arr) => onSearchMultiFilterJson(arr, "slave_address")}
									maxItems={null} // Allow any number of items
									onClear={(arr) => onItemClearedFilterJson(arr, "slave_address")}
									onClearAll={(arr) => onClearAllItemsFilterJson(arr, "slave_address")}
								/>}

							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext3" name="Modem_Serial_No" className="custom-control-input" value="modemserial" checked={consumer.checktext3} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext3">Slave Serial No</label>
								</div>
								{consumer.checktext3 && <MultiInput
									placeholder="search and enter..."
									onChange={(arr) => onSearchMultiFilterJson(arr, "Modem_Serial_No")}
									maxItems={null} // Allow any number of items
									onClear={(arr) => onItemClearedFilterJson(arr, "Modem_Serial_No")}
									onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Modem_Serial_No")}
								/>}

							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktext5" name="Unique_ID" className="custom-control-input" value="cnuniqueid" checked={consumer.checktext5} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktext5">Consumer Unique Id</label>
								</div>
								{consumer.checktext5 && <MultiInput
									placeholder="search and enter..."
									onChange={(arr) => onSearchMultiFilterJson(arr, "Unique_ID")}
									maxItems={null} // Allow any number of items
									onClear={(arr) => onItemClearedFilterJson(arr, "Unique_ID")}
									onClearAll={(arr) => onClearAllItemsFilterJson(arr, "Unique_ID")}
									value={filterObj?.Unique_ID}
								/>}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextCS" name="Status" className="custom-control-input" value="filterstatus" checked={consumer.checktextCS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextCS">Consumer Status</label>
								</div>
								{consumer.checktextCS && (<div className="form-group col-md-3 checktextCS filterstatus" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Status' options={consumers} onChange={(e) => {
										if (!e && e?.value != 1 && e?.value != 0) {
											delete filterObj["SubStatus"]
										}
										setFilterObj({ ...filterObj, Status: e?.value || '' })
										setShowFilterObj({ ...showfilterObj, Status: e?.label || '' })
									}} isClearable value={[filterObj?.Status ? consumers.filter((item) => (item?.value == filterObj?.Status))[0] : []]} />

								</div>)}
							</div>

							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextMOP" name="Modem_Profile" className="custom-control-input" value="modemprofile" checked={consumer.checktextMOP} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextMOP">Slave Profile</label>
								</div>
								{consumer.checktextMOP && (<div className="form-group col-md-3 checktextMOP modemprofile" style={{ marginTop: -10 }}>
									<Select styles={customStyles} components={animatedComponents} name='Modem_Profile' options={MeterData} isMulti onChange={(e) => {
										setFilterObj({ ...filterObj, Modem_Profile: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Modem_Profile: e.map((item) => (item.label)) })
									}} />

								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextTA" name="Group" className="custom-control-input" value="tagsvalue" checked={consumer.checktextTA} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextTA">Group</label>
								</div>
								{consumer.checktextTA && (<div className="form-group col-md-3 checktextTA tagsvalue" style={{ marginTop: -10 }}>
									<Select styles={customStyles} name='Group' isMulti options={GroupDetails} onChange={(e) => {
										setFilterObj({ ...filterObj, Group: e.map((item) => (item.value)) })
										setShowFilterObj({ ...showfilterObj, Group: e.map((item) => (item.label)) })
									}
									} />

								</div>)}

							</div>
						</div>
					</div>
				</fieldset>

				{/* <fieldset className="customLegend ">
					<legend >Modem Data Wise Filter</legend>
					<div className="row" style={{ marginLeft: 10 }}>
						<div className="col-12">
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextRSSI" name="RSSI" className="custom-control-input" value="rssival" checked={modem.checktextRSSI} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextRSSI">RSSI</label>
								</div>
								{modem.checktextRSSI && (<div className="form-group col-md-3 checktextRSSI length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='RSSI' options={dataWise}
										styles={customStyles} onChange={handleSelectChange} />

								</div>)}
								{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, From: e.target.value } }) }} />
								</div>)}
								{modemStart.Between && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, To: e.target.value } }) }} />
								</div>)}
								{modemStart.LessEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
								</div>)}
								{modemStart.GreaterEqual && modem.checktextRSSI && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, RSSI: { ...filterObj.RSSI, Value: e.target.value } }) }} />
								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextSNR" name="SNR" className="custom-control-input" value="rssival" checked={consumer.checktextSNR} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextSNR">SNR</label>
								</div>
								{consumer.checktextSNR && (<div className="form-group col-md-3 checktextSNR length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='SNR' options={dataWise}
										styles={customStyles} onChange={(e)=>handleSNRSFChange(e,'SNR')} />

								</div>)}
								{modemStart.Between && consumer.checktextSNR && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, SNR: { ...filterObj.SNR, From: e.target.value } }) }} />
								</div>)}
								{modemStart.Between && consumer.checktextSNR && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, SNR: { ...filterObj.SNR, To: e.target.value } }) }} />
								</div>)}
								{modemStart.LessEqual && consumer.checktextSNR && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, SNR: { ...filterObj.SNR, Value: e.target.value } }) }} />
								</div>)}
								{modemStart.GreaterEqual && consumer.checktextSNR && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, SNR: { ...filterObj.SNR, Value: e.target.value } }) }} />
								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextSF" name="SF" className="custom-control-input" value="rssival" checked={consumer.checktextSF} onChange={(e)=>handleChangeS(e)} />
									<label className="custom-control-label" for="checktextSF">SF</label>
								</div>
								{consumer.checktextSF && (<div className="form-group col-md-3 checktextSF length rssival" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} placeholder="Select Condition..." name='SF' options={dataWise}
										styles={customStyles} onChange={(e)=>handleSNRSFChange(e,'SF')} />

								</div>)}
								{modemStart.Between && consumer.checktextSF && (<div className="form-group col-md-3 checktext14" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_from" id="filter_rssi_from" placeholder="From" onChange={(e) => { setFilterObj({ ...filterObj, SF: { ...filterObj.SF, From: e.target.value } }) }} />
								</div>)}
								{modemStart.Between && consumer.checktextSF && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_to" id="filter_rssi_to" placeholder="To" onChange={(e) => { setFilterObj({ ...filterObj, SF: { ...filterObj.SF, To: e.target.value } }) }} />
								</div>)}
								{modemStart.LessEqual && consumer.checktextSF && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_less_then" id="filter_rssi_less_then" placeholder="less than..." onChange={(e) => { setFilterObj({ ...filterObj, SF: { ...filterObj.SF, Value: e.target.value } }) }} />
								</div>)}
								{modemStart.GreaterEqual && consumer.checktextSF && (<div className="form-group col-md-3 checktext13" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_rssi_greater_then" id="filter_rssi_greater_then" placeholder="greter than..." onChange={(e) => { setFilterObj({ ...filterObj, SF: { ...filterObj.SF, Value: e.target.value } }) }} />
								</div>)}
							</div>
							<div className="form-row">
								<div className="custom-control custom-checkbox">
									<input type="checkbox" id="checktextLS" name="Last_Seen" className="custom-control-input" value="lastssen" checked={modem.checktextLS} onChange={handleChangeS} />
									<label className="custom-control-label" for="checktextLS">Last seen</label>
								</div>
								{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWiseOption} placeholder="Select Condition..."
										onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Last_Seen: e.value } }) }} />
								</div>)}
								{modem.checktextLS && (<div className="form-group col-md-3 checktextLS lastssen" style={{ marginTop: -10 }}>
									<Select components={animatedComponents} styles={customStyles} name='Last_Seen' options={lastSeenWise} placeholder="Select Condition..."
										onChange={handleLastSeen} />
								</div>)}

								{last.LessEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_hours" id="filter_less_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
								</div>)}

								{last.GreaterEqual && modem.checktextLS && (<div className="form-group col-md-3 checktext31" style={{ marginTop: -10 }}>
									<input type="text" className="form-control" name="filter_hours" id="filter_greater_hours" placeholder="" onChange={(e) => { setFilterObj({ ...filterObj, Last_Seen: { ...filterObj.Last_Seen, Value: e.target.value } }) }} />
								</div>)}
							</div>



						</div>
					</div>
				</fieldset> */}
				<div className="text-left mt-2">
					<input type="submit" className={`btn btn-danger mr-2 ${FilterLoader == true ? 'btn-progress disabled' : ''}`} value="Filter" name="filter_customer_form" onClick={onSubmiteFilter} />
					<Link className="btn btn-light text-dark" to="/All-GSM">Reset</Link>
				</div>



			</Modal>
			<Modal title="Generate GSM Reports" visible={ismodel} footer='' onCancel={handleCancel} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
								<form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
									<div className="form-row mt-2" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger mr-2'>*</span><BootstrapTooltip title={ReportType?.filter((item) => (item?.value == report?.value))[0]?.description || ''} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport} value={report?.value}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
												{/* <option value={'Hourly-Consumption'}>Hourly Consumption</option> */}
											</select>

										</div>
										{report?.value != '' ? <>
											{(report.value == 'RTI3459856669') && <>
												<div className="form-group col-md-2">
													<label htmlFor="intervalrType ">Interval Type :<span className='text-danger mr-2'>*</span></label>
													<select className="form-control form-select" name="intervalrType" required="required" id='intervalrType' onChange={(e, dateString) => setReport({ ...report, intervalrType: e.target.value, start_date: '', end_date: '' })} value={report?.intervalrType}>
														<option value=""></option>
														<option value={'daily'}>Daily</option>
														<option value={'weekly'}>Weekly</option>
														<option value={'monthly'}>Monthly</option>
													</select>
												</div>
											</>}
											{report?.value == 'RTI3452366456'|| (report.value == 'RTI3459856669' && report?.intervalrType === 'monthly')  ? <><div className="form-group col-md-3" id="strdate">
												<label for="inputPassword4">Start Month :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='MM-YYYY' name='start' picker="month" style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment().add(0, 'months'))
													}} defaultValue={report.start_date ? moment(report.start_date, 'MM-YYYY') : ''} />
											</div>
												<div className="form-group col-md-3" id="enddate" >
													<label for="inputPassword4">End Month :<span className='text-danger'>*</span> </label><br></br>
													<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='MM-YYYY' name='start' picker="month" style={{ width: '100%', height: '40px' }}
														disabledDate={(current) => {
															return current.isAfter(moment().add(0, 'months'))
														}} defaultValue={report.end_date ? moment(report.end_date, 'MM-YYYY') : ''} />
												</div></>
												: ''}
											{(report?.value == 'RTI3459856623' || report.value == 'RTI5632154893' || report.value == 'RTI3459856670' || (report.value == 'RTI3459856669' && report?.intervalrType === 'daily')) ? <><div className="form-group col-md-3" id="strdate">
												<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment())
													}} defaultValue={report.start_date ? moment(report.start_date, 'MM-YYYY') : ''} />
											</div>
												<div className="form-group col-md-3" id="enddate" >
													<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
													<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
														disabledDate={(current) => {
															return current.isAfter(moment())
														}} defaultValue={report.end_date ? moment(report.end_date, 'DD-MM-YYYY') : ''} />
												</div></>
												: ''}

											{(report.value == 'RTI3459856669' && report?.intervalrType === 'weekly') ? <><div className="form-group col-md-3" id="strdate">
												<label for="inputPassword4">Start Week :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker picker="week" onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment())
													}} defaultValue={report.start_date ? moment(report.start_date, 'MM-YYYY') : ''} />
											</div>
												<div className="form-group col-md-3" id="enddate" >
													<label for="inputPassword4">End week  :<span className='text-danger'>*</span> </label><br></br>
													<DatePicker picker="week" onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} name='start' style={{ width: '100%', height: '40px' }}
														disabledDate={(current) => {
															return current.isAfter(moment())
														}} defaultValue={report.end_date ? moment(report.end_date, 'DD-MM-YYYY') : ''} />
												</div></>
												: ''}

											{(report.value == 'RTI3459856623' || report.value == 'RTI5632154893') ? <><div className="form-group col-md-1">
												<label for="Start Interval">Start Time :<span className='text-danger'>*</span> </label>
												<div>
													<TimePicker style={{ padding: '10px', width: '100%' }} onChange={(e, tsstring) => {
														console.log(e, tsstring)
														setReport({ ...report, start_interval: tsstring })
													}} defaultValue={report?.start_interval ? dayjs(report?.start_interval, 'HH:mm:ss') : ''}></TimePicker>
												</div>
											</div>

											</> : ""}
											{report?.start_interval ? <div className="form-group col-md-1">
												<label for="End Interval">End time :<span className='text-danger'>*</span> </label>
												<div>
													<TimePicker style={{ padding: '10px', width: '100%' }} disabled value={report?.start_interval ? dayjs(report?.start_interval, 'HH:mm:ss') : ''} format="HH:mm:ss"></TimePicker>
												</div>
											</div> : ''}
											{/* <div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return current.isAfter(moment().add(-1, 'days'))||current>moment(report?.start_date,'DD-MM-YYYY HH:mm:ss').add(16, 'days')||current< moment(report?.start_date,'DD-MM-YYYY HH:mm:ss')
													}} value={report.end_date?moment(report.end_date,'DD-MM-YYYY'):''} disabled={report?.start_date==''?true:false}/>
											</div> */}

										</> : ''}

										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLSX</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${reportLoader ? 'disabled' : ''}`} value="Generate" id="submitvn" onClick={ongetGenretedAllGSMReport} />
										<a className="btn btn-light text-dark" onClick={handleCancel}>Cancel</a>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</Modal>
			<Modal title="Reports" visible={isreportHistory} footer='' onCancel={() => setisreportHistory(false)} width={1500}>
				{isreportHistory && <Reports isreportHistory={isreportHistory} />}
			</Modal>
			{OpenConsumerDash == false && <section className="section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4>Slave</h4>
									<div className="card-header-action">
										<Link onClick={() => setisModalModem(true)} className="btn btn-info text-white mr-2">
											<BootstrapTooltip title="Add">
												<span>
													<i
														className="fas fa-plus  text-white"
														style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
													></i>
												</span>
											</BootstrapTooltip>
										</Link>
										<button type="button" className="btn btn-info mr-2" onClick={() => setismodelFilter(true)}>
											<BootstrapTooltip title="Filter">
												<span>
													<i className="fa fa-filter text-white"></i>
												</span>
											</BootstrapTooltip>
										</button>
										<div className="dropdown d-inline mr-2">
											<button
												className="btn btn-info dropdown"
												type="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												<div>
													<i className="fas fa-download text-white" style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}></i>

												</div>
											</button>
											<div
												className="dropdown-menu tab-setting"
												x-placement="bottom-start"
												style={{
													position: "absolute",
													transform: "translate3d(-5px, 28px, 0px)",
													top: "0px",
													left: "-110px",
													willChange: "transform",
												}}>
												<a className="dropdown-item has-icon" onClick={showModal} >
													Generate Report
												</a>
												<Link to={component_type == 'Group' ? '/Slave-Report' : ''} className="dropdown-item has-icon" onClick={() => component_type == 'Group' ? '' : setisreportHistory(true)}>
													Report History
												</Link>
											</div>
										</div>
									</div>
								</div>
								<div className="card-body">
									<FilterDisplay filter={{ ...filterObj, ...showfilterObj }} onClearFilter={onClearFilter} />
									<div className="table-responsive">
										<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
											<div class="dataTables_length" id="table_id_length">
												<label>
													Show{" "}
													<select name="table_id_length" aria-controls="table_id" class="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
														<option value={10}>10</option>
														<option value={25}>25</option>
														<option value={50}>50</option>
														<option value={100}>100</option>
													</select>{" "}
													entries
												</label>
											</div>
											<div id="table-1_filter" className="dataTables_filter"><label className="mr-2">Search:</label><MultiInput
												placeholder="search and enter..."
												onChange={onHandalSearchMulti}
												maxItems={null} // Allow any number of items
												onClear={onItemCleared}
												onClearAll={onClearAllItems}
												onHandalSerch={onHandalSerch}
											/>
												<div className={`ml-2 btn btn-primary ${serachLoader === true ? 'btn-progress disabled' : ''}`} onClick={onHandalSerch} style={{ padding: '0.2rem 0.8rem', top: '0' }}><i className={`fas fa-search `}></i></div></div>
											<table id="table_id" className="table border text-center">
												<thead>
													<tr>
														<th className="text-center !tw-font-semibold">Unique No</th>
														<th className='!tw-font-semibold'>Feeder Name</th>
														{/* <th className='text-center !tw-font-semibold'>Modem Type</th> */}
														<th className='text-center !tw-font-semibold'>Slave Address</th>
														<th className='text-center !tw-font-semibold'>Slave Profile</th>
														<th className='text-center !tw-font-semibold'>Consumer Name</th>
														<th className='text-center !tw-font-semibold'>Group</th>
														{/* <th className='text-center !tw-font-semibold'>Longitude</th> */}
														<th className='text-center !tw-font-semibold'>Status</th>
														<th className='text-center !tw-font-semibold'>Information</th>
													</tr>
												</thead>
												<tbody>
													{getwayList.length > 0 && getwayList.map((item, id) => (
														<tr className="trheigth hover:tw-bg-[#D8D8D8]" onMouseEnter={() => {
															if (component_type != 'Group') {
																var element = document.getElementById(item?.sr_no_u + 'GSM');
																element.classList.add('within');
																element.classList.remove('display-icon');
															}
														}} onMouseLeave={() => {
															if (component_type != 'Group') {
																var element = document.getElementById(item?.sr_no_u + 'GSM');
																element.classList.add('display-icon');
																element.classList.remove('within');
															}

														}}>
															{/* <td className='tw-text-center' onClick={() => (onHandaleViewGSM(item))}>{item?.sr_no_u}</td> */}
															{component_type != 'Group' ? <td className="GSM_Link" onClick={() => (onHandaleViewGSM(item))}>
																<a className='!tw-text-[#1890ff]'>{item?.sr_no_u}</a>
																<a id={item?.sr_no_u + 'GSM'} className='display-icon' ><img src={exports} style={{ height: "10px" }} /></a></td> : <td><a href={`/view_existing_consumer_gsm/${item?.consumer_unique_id}?active_tab=slave&slave_id=${item?.unique_id}`} target='_blank'>{item?.sr_no_u}</a></td>}
															<td>{item?.feeder_name}</td>
															<td>{item?.slave_address}</td>
															<td>{item?.slave_profile_name}</td>
															<td>{item?.company_name}</td>
															<td>{item?.slave_group_list}</td>
															{/* <td>{item?.longitude}</td>  */}
															<td className="text-center">
																<div className={`badge ${item?.slave_status == 1 ? 'badge-success' : item?.slave_status == -1 ? 'badge-warning' : item?.slave_status == 0 ? 'badge-danger' : item?.slave_status == 2 ? 'badge-info' : 'badge-dark'} badge-shadow text-white`}>{item?.slave_status == 1 ? 'Active' : item?.slave_status == -1 ? 'Pending Configration' : item?.slave_status == 0 ? 'Inactive' : item?.slave_status === 2 ? 'Under Maintenance' : 'Stand By'}</div>
															</td>
															<th><BootstrapTooltip title='Info' placement='top'><a class="btn btn-primary mr-2" style={{ background: '#6777ef', color: 'white', padding: '5px', borderRadius: '5px' }}><i class="fa fa-info-circle" aria-hidden="true"></i></a></BootstrapTooltip>

															</th>
														</tr>
													))}


												</tbody>
											</table>
										</div>
									</div>
									<><div className='d-flex justify-content-between'>
										<div>Total Number Of Row {numberofPage}</div><Pagination
											className="pagination-bar"
											currentPage={currentPage}
											totalCount={numberofPage || 1}
											pageSize={PageSize}
											onPageChange={(page) => setCurrentPage(page)}
										/></div></>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>}
			{OpenConsumerDash == true && <Lora_deshboard ConsumerDashDetails={ConsumerDashDetails} setOpenConsumerDash={setOpenConsumerDash} ModemDetails={GSMdata} />}
		</>
	)
}
