import React, { useEffect, useMemo, useState } from 'react'
import { getParticularSlaveTemperatureAnalysis, getPowerSourceTotalGSM, getSlaveTempratureanalysis, getSlaveTempratureRangeDetails, onGetConsumerIncolumeName, onGetSlaveConsumerIncolumeName } from '../../Common/Utils';
import { Card, CardContent, CardFooter, CardHeader, TaskFilterButton } from '../../Common/ui/card';
import ReactApexChart from "react-apexcharts";
import loaderGif from '../../assets/img/loading_chart.gif'
import { Modal, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { colorStyles } from '../../Common/common';

const sum = (arr) => {
	const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
	return result
}

export default function EnvironmentDashboard(props) {
    const {permision}=props
    const [TempRange, setTempRange] = useState({ series: [], category: [], code: [] })
    const [seriesTemp, setseriesTemp] = useState([])
    const [TableData, setTableDataInApi] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [ModelId, setModelId] = useState('')
    const [ModalLoad, setModalLoad] = useState(false)
    const [tempAnalysis, setTempAnalysis] = useState({})
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });

    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    let colors = ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dcf', '#FF7700', '#554994', "#D36B00", "#0096FF"];

    useEffect(()=>{
        console.log('permision',permision);
        if(permision){
          const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
        ongetPowerSourceTotalGSM(infoRolesObj)
        ongetSlaveTempratureanalysis(infoRolesObj)
        }
    },[permision])

    const GetConsumerIncolumeName = async (isbetween, col, colname, value, CARD_ID) => {
      setIsModalVisible(true)
      setModalLoad(true)
      setModelId(`${colname}`)
      let obj = {}
     
        obj = { isbetween: isbetween, colName: col, value: value, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, ins_no: Profile.ins_no, ROLES: userInfo?.ROLE, serach: Profile.account === '' && Profile.ga === '' && Profile.circle === '' && Profile.division === '' && Profile.subdivision === '' && Profile.area === '' && Profile?.ins_no?.length == 0 ? false : true,Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
      
      const data = await onGetSlaveConsumerIncolumeName(obj)
      if (data.success === true) {
        let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
        setTableDataInApi(tempData)
        setModalLoad(false)
      } else {
        console.log('somthing want wrong')
        setModalLoad(false)
  
      }
    }

    const ongetPowerSourceTotalGSM = async (info) => {
      setTempRange({ series: [], category: [] })
        const data = await getSlaveTempratureRangeDetails(info);
        if (data.success === true) {
          const chartData = data?.data?.data;
          setTempRange({series:chartData.map(item=>item?.counts),category:chartData.map(item=>item?.Ranges?item?.Ranges:'null')});
        } else {
          console.log("something went warong");
        }
      }

    const ongetSlaveTempratureanalysis = async (info) => {
      setTempAnalysis({})
      const data = await getSlaveTempratureanalysis(info);
      if (data.success === true) {
        setTempAnalysis(data?.data?.result || {})
      } else {
        console.log("something went warong");
      }
    }

    const ongetParticularSlaveTemperatureAnalysis = async ({ condition, colname }) => {
      setIsModalVisible(true)
      setModalLoad(true)
      setModelId(`${colname}`)
      const infoRolesObj = { ROLES: userInfo?.role, Profile: { CATEGORY0: permision?.roles_permission_category0?.CATEGORY0_view, CATEGORY1: permision?.roles_permission_category1?.CATEGORY1_view, CATEGORY2: permision?.roles_permission_category2?.CATEGORY2_view, CATEGORY3: permision?.roles_permission_category3?.CATEGORY3_view, CATEGORY4: permision?.roles_permission_category4?.CATEGORY4_view, CATEGORY5: permision?.roles_permission_category5?.CATEGORY5_view } }
      const data = await getParticularSlaveTemperatureAnalysis({ condition,...infoRolesObj });
      if (data.success === true) {
        let tempData = data?.data?.data.map((item) => ({ ...item, MMD_STATUS_PWR_SOURCE: item?.HEARTBEAT_LAST_DATA ? JSON.parse(item?.HEARTBEAT_LAST_DATA || '{}').MMD_STATUS_PWR_SOURCE : null, ALERT_MMD_STATUS_PWR_SOURCE: item?.ALERT_DISABLE ? JSON.parse(item?.ALERT_DISABLE || '{}').MMD_STATUS_PWR_SOURCE : null }));
        setTableDataInApi(tempData)
        setModalLoad(false)
      } else {
        console.log("something went warong");
        setModalLoad(false)
      }
    }


      var options1 = getPieChartOptions(
        350,
        TempRange.category,
        colors,
        function (event, chartContext, config) {
          var date_index = config.dataPointIndex;
          console.log(typeof TempRange.category[date_index]);
            GetConsumerIncolumeName(false, '', TempRange.category[date_index].split('(')[0], TempRange.category[date_index]!='null'&&TempRange.category[date_index]?`(JSON_VALUE(temp_config,'$.MIN')=${TempRange.category[date_index].split('-')[0]} AND  JSON_VALUE(temp_config,'$.MAX')=${TempRange.category[date_index].split('-')[1]})`:`temp_config is null or (JSON_VALUE(temp_config,'$.MIN') is null AND JSON_VALUE(temp_config,'$.MAX') is null)`)
        },
        function (val, opt) {
          return TempRange.series[opt.seriesIndex];
        }
      );

      function getPieChartOptions(height, labels, colors, dataPointSelectionHandler, dataLabelFormatter) {
        return {
          chart: {
            height: height,
            type: 'pie',
            events: {
              dataPointSelection: dataPointSelectionHandler,
              dataPointMouseEnter: function (event) {
                if(event.path){
                  event.path[0].style.cursor = "pointer";
                }
              }
            }
          },
          legend: {
            position: 'right',
            verticalAlign: 'bottom',
            width: 150
          },
          labels: labels,
          dataLabels: {
            enabled: true,
            textAnchor: "start",
            formatter: dataLabelFormatter || function (val) { return val; }, // Default formatter
            offsetX: 0,
          },
          responsive: [{
            breakpoint: 1500,
            options: {
              chart: {
                width: "100%",
              },
              legend: {
                position: 'right',
                width: 150
              }
            }
          }, {
            breakpoint: 1300,
            options: {
              chart: {
                width: "100%",
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 1100,
            options: {
              chart: {
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 700,
            options: {
              chart: {
                height: 380
              },
              legend: {
                position: 'bottom',
                width: "100%",
                height: 100
              }
            }
          }, {
            breakpoint: 500,
            options: {
              chart: {
                height: 350
              },
              legend: {
                show: false
              }
            }
          }],
          colors: colors
        };
      }

      const handleCancel = () => {
        setIsModalVisible(false);
        setTableDataInApi([])
      };

      const onHandaleViewGSM = (id) => {
        window.open(`/view_existing_consumer_gsm/${id}`, '_blank')
      }

      const onHandaleViewSlave = (id, slave) => {
        window.open(`/view_existing_consumer_gsm/${id}?active_tab=slave&slave_id=${slave}/`, '_blank')
      }

      const lookupForTemp = useMemo(() => [
        {
          label: 'Ok',
          condition: 'WITHIN_RANGE',
          value: tempAnalysis?.withinRange || 0,
        },
        {
          label: 'Above Maximum',
          condition: 'ABOVE_MAX',
          value: tempAnalysis?.aboveMax || 0,
        },
        {
          label: 'Below Minimum',
          condition: 'BELOW_MIN',
          value: tempAnalysis?.belowMin || 0,
        },
        {
          label: 'No Data',
          condition: 'NULL_DATA',
          value: tempAnalysis?.nullCount || 0,
        },
        {
          label: 'Data Not Received Within 15 mins.',
          condition: 'DATA_NOT_FOUND',
          value: tempAnalysis?.dataNotFoundIn15Min || 0,
        }
      ], [tempAnalysis]);
    return (
      <>
       <Modal title={<div className="d-flex"><h5 >{ModelId}</h5>{TableData.length > 0 ? <span className='btn btn-primary text-white ml-2' ><i className='fas fa-download'></i></span> : ''}{TableData.length > 0 ? <Link to={{ pathname: `All-GSM`, state: { detail: TableData.map((data) => (data?.UNIQUE_ID)), title: ModelId } }}><span className='btn btn-primary text-white ml-2'><i className='fas fa-up-right-from-square'></i></span></Link> : ''}</div>} maskClosable={false} className="my-modal-class" visible={isModalVisible} footer='' onCancel={handleCancel} width={1000} >
        <div>
          <div className="modal-table">
            {ModalLoad ? (
              <div className="example">
                <Spin size="large" />
              </div>
            ) : <table className="table border text-center table-borderless" id="table-1">
              <thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
                <tr>
                  <th className="text-center">SR NO.</th>
                  <th >Feeder Name</th>
                  <th >Company/Client Name</th>
                  <th>Modem Serial No</th>
                   </tr>
              </thead>
              <tbody id="tbody">
                <>
                  {TableData.length > 0 ?
                    TableData.map((data, id) => (
                      <tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id} onClick={() => onHandaleViewGSM(data?.UNIQUE_ID)}>
                        <td className="text-center">{id + 1}</td>
                        <td className='tw-cursor-pointer' onClick={() => onHandaleViewGSM(data?.consumer_unique_id)}   >{data?.address_compony_name}</td>
                        <td className='tw-cursor-pointer' onClick={() => onHandaleViewSlave(data?.consumer_unique_id, data?.unique_id)} >{data?.feeder_name}</td>
                        <td>{data?.slave_address || " - "}</td>
                      </tr>
                    )) : <tr></tr>}
                </>
              </tbody>
            </table>}
            {TableData.length == 0 && !ModalLoad && <h4 className="text-center">No Data Found</h4>}

          </div>
        </div>
      </Modal>
        <div className='tw-mt-5' >
           
            <div className='tw-grid tw-gap-x-6 tw-gap-y-4 tw-grid-cols-12' >
            <div className="md:tw-col-span-6 tw-col-span-12">
                    <Card className=' !tw-border-t-btninfo' >
                    <CardHeader IsDrag={false} title='Temperature Range' className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b tw-p-4 tw-px-6" tooltip={`Find Data In gsm_consumer_device_index (MMD_STATUS_PWR_SOURCE) 
                        If HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 1 OR ALERT_DISABLE.MMD_STATUS_PWR_SOURCE IS NUll OR  ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then External Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 1 AND HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 0 Then Battery Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then Batter Backup Not Available`}  >
                        <div className="d-flex tw-items-center tw-gap-2">
                         <h2>Temperature Range</h2>
                        </div>
                      </CardHeader>
                      <CardContent>
                        <div
                          className="card-body"
                          style={{ position: "relative", paddingLeft: "0px", paddingRight: "0px", height: "395px" }}>
                          {TempRange?.series?.length > 0 && TempRange?.category?.length > 0 ? <ReactApexChart options={options1} type="pie" series={TempRange?.series} height={options1.chart.height} /> : <div className="d-flex" style={{ justifyContent: 'center' }}><img className="loader_center text-center" src={loaderGif} /></div>}
                        </div>
                        <CardFooter className='tw-flex tw-justify-between tw-px-3 !tw-pb-0' >
                          <p className='!tw-m-0'>Total Count : {sum(TempRange?.series)} </p>
                          <p className='!tw-m-0'>{'Pie chart(Dynamic)'} </p>
                        </CardFooter>
                      </CardContent>
                    </Card>
                  {/* </DraggableHelper> */}
                </div>

                <div className="md:tw-col-span-6 tw-col-span-12 tw-h-full">
            <Card className=' !tw-border-t-btninfo ' >
              <CardHeader IsDrag={false} title='Temperature Range' className="tw-flex tw-justify-between tw-items-start tw-flex-row tw-border-b tw-p-4 tw-px-6" tooltip={`Find Data In gsm_consumer_device_index (MMD_STATUS_PWR_SOURCE) 
                        If HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 1 OR ALERT_DISABLE.MMD_STATUS_PWR_SOURCE IS NUll OR  ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then External Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 1 AND HEARTBEAT_LAST_DATA.MMD_STATUS_PWR_SOURCE = 0 Then Battery Power
                        If ALERT_DISABLE.MMD_STATUS_PWR_SOURCE = 0 Then Batter Backup Not Available`}  >
                <div className="d-flex tw-items-center tw-gap-2">
                  <h2>Temperature Data</h2>
                </div>
              </CardHeader>
              <CardContent>
                {lookupForTemp.map(({ label, value, condition }, index) => (
                  <TaskFilterButton
                    key={index}
                    label={label}
                    count={value}
                    onClick={() => ongetParticularSlaveTemperatureAnalysis({ condition, colname: label })}
                    mainStyle={colorStyles[index]?.mainStyle}
                    countStyle={colorStyles[index]?.countStyle}
                  />
                ))}
              </CardContent>
            </Card>
          </div>
            </div>
            {/* </div> */}
        </div>
        </>
    )
}

export const CardBody = ({ children, CardTitle, isBodyClass = true, className, bodyClassName }) => {
    return (
        <div className={`tw-text-card-foreground tw-shadow-md tw-w-full tw-my-4 tw-py-2 border tw-bg-card tw-rounded ${className ?? className}`} style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 5px 10px 0px" }} >
            <div className='tw-flex tw-flex-col tw-space-y-1.5' >
                <h5 className='tw-font-semibold tw-leading-none tw-tracking-tight border-bottom tw-px-2 tw-py-3 tw-mb-0' >
                    {CardTitle}
                </h5>
            </div>
            <div className={`${isBodyClass ? "tw-grid tw-gap-1 tw-px-6 tw-pb-4" : ""} ${bodyClassName ?? bodyClassName}`} >
                {children}
            </div>
        </div>
    )
}
