import React, { useEffect, useState } from 'react'
import { getAllCategoryTableData, getRolesById } from '../../Common/Utils';
import { Link } from 'react-router-dom';
import TabButton from './components/TabBtn';
import { Card, CardHeader } from '../../Common/ui/card';
import GoogleLoader from '../../Common/Loaders/GoogleLoader';
import IOTDashboard from './Dashboard';
import ModemDashboard from './ModemDashboard';
import EnvironmentDashboard from './EnvironmentDashboard';

export default function MainDashboard() {

    const [activeTab, setActiveTab] = useState("");
    const [activeSubTab, setActiveSubTab] = useState("");
    const userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const [subActiveTab, setSubActiveTab] = useState("");
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] });
    const [isDownloadBtnShow, setisDownloadBtnShow] = useState(true);
    const [permision, setpermision] = useState({});
    const [staticActiveTab, setStaticActiveTab] = useState()
    const [tabLoader, setTabLoader] = useState(true)
    const [AllCategory, setAllCategory] = useState({});

    useEffect(() => {
        setActiveSubTab(null)
        setStaticActiveTab(null)
        setSubActiveTab(null)
        onGetAllCategoryTableData()
    }, [activeTab])


    const onGetAllCategoryTableData = async (info) => {
        const data = await getAllCategoryTableData()
        if (data.success === true) {
            setAllCategory(data.data.data)
        } else {
            console.log('something went wrong')
        }
    }

    const onHandleRoles = async () => {
        const data = await getRolesById(userInfo?.role)
                if (data.success === true) {
                    const obj = data.data.data[0]
                    setpermision({
                        ...obj,
                        roles_permission_category0: JSON.parse(
                            obj.roles_permission_category0 || '{}'
                        ),
                        roles_permission_category5: JSON.parse(
                            obj.roles_permission_category5 || '{}'
                        ),
                        roles_permission_category2: JSON.parse(
                            obj.roles_permission_category2 || '{}'
                        ),
                        roles_permission_category3: JSON.parse(
                            obj.roles_permission_category3 || '{}'
                        ),
                        roles_permission_category1: JSON.parse(
                            obj.roles_permission_category1 || '{}'
                        ),
                        roles_permission_category4: JSON.parse(
                            obj.roles_permission_category4 || '{}'
                        ),
                        roles_other_permission: JSON.parse(
                            obj.roles_other_permission || '{}'
                        ),
                    })
        }
    }

    useEffect(() => {
        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "", ins_no: [] })
        setisDownloadBtnShow(true)
    }, [activeTab, subActiveTab])


    useEffect(() => {
       (async () => {
            setTabLoader(true);
            await onHandleRoles();
            setTabLoader(false);
        })()
    }, []);


    return (
        <>
            <div className="main-content" style={{ minHeight: "562px" }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-deshboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            Dashboard
                        </li>
                    </ol>
                </nav>

                <section class="section">
                    <div class="section-body">
                        <div class="row">
                        {tabLoader ?
                                <div className="tw-p-4 tw-flex tw-items-center tw-justify-center">
                                    <GoogleLoader />
                                </div> : <div class="col-12 col-sm-12 col-lg-12 tw-p-0">
                                <Card className='!tw-border-t   !tw-border-t-slate-100'  >
                                    <CardHeader className="d-flex !tw-p-2 " style={{ justifyContent: 'space-between' }}>
                                        <ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
                                        <TabButton
                                                label="Modem Dashboard*"
                                                isActive={activeTab === "Modem Dashboard*"}
                                                onClick={() => setActiveTab('Modem Dashboard*')}
                                                permission={true}
                                                btnColorIndex={0}
                                        />
                                        <TabButton
                                            label="Environmental Perameter Dashboard*"
                                            isActive={activeTab === "Environmental Perameter Dashboard*"}
                                            onClick={() => setActiveTab('Environmental Perameter Dashboard*')}
                                            permission={true}
                                            btnColorIndex={2}
                                        />
                                        <TabButton
                                            label="Electrical Perameter Dashboard*"
                                            isActive={activeTab === "Electrical Perameter Dashboard*"}
                                            onClick={() => setActiveTab('Electrical Perameter Dashboard*')}
                                            permission={true}
                                            btnColorIndex={3}
                                        />
                                        </ul>
                                    </CardHeader>

                                </Card>

                                
                            </div>}
                            {activeTab === 'Electrical Perameter Dashboard*' &&<IOTDashboard/>}
                            {activeTab === 'Modem Dashboard*' &&<ModemDashboard permision={permision}/>}
                            {activeTab === 'Environmental Perameter Dashboard*' &&<EnvironmentDashboard permision={permision}/>}

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
